<template>
  <div>
    <div class="col-sm-12 mt-3">
      <h5 class="main-question-qualif">{{$t("simulation.import.title")}}</h5>
    </div>
    <div class="mt-4">
      <h5 class="main-description" style="margin:32px 0 24px"> {{$t("simulation.import.description")}}</h5>
    </div>
    <div class="col-sm-12 background" style="padding: 30px 80px 40px">
      <div class="col-sm-12">
        <img class="img-fluid imagecenter" src='/assets/images/ssd.png'/>
        <div style="margin-top:20px">
          <form v-on:submit.prevent="validate" id="joinForm" novalidate>
            <div class="form-group" :class="{ 'has-error': $v.input.username.$error }">
              <label for="usernameImport" class="control-label-ss">{{$t("simulation.ssd.username")}}</label>
              <input class="form-colmena-white" type="text" name="usernameImport" id="usernameImport" :placeholder='$t("simulation.ssd.username.placeholder")'
                     :class="{'is-invalid' : $v.input.username.$error}"
                     v-model.trim="$v.input.username.$model"/>
              <span class="invalid-feedback" v-show="$v.input.username.$error && !$v.input.username.required">
                                {{$t("simulation.required")}}</span>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.input.password.$error }">
              <label for="passwordImport" class="control-label-ss">{{$t("simulation.ssd.password")}}</label>
              <input class="form-colmena-white" type="password" name="passwordImport" id="passwordImport"  :placeholder='$t("simulation.ssd.password.placeholder")'
                     :class="{'is-invalid' : $v.input.password.$error}"
                     v-model.trim="$v.input.password.$model"/>
              <span class="invalid-feedback" v-show="$v.input.password.$error && !$v.input.password.required">
                                {{$t("simulation.required")}}</span>
            </div>
          </form>
        </div>
        <v-button type="submit" :full="true" :green="true" v-on:click="stepAutomatico" :loading="save_click" :text='$t("simulation.enter")'/>
      </div>
    </div>

    <div  class="justify-content-center"  style="text-align: center;margin-top:24px">
      <span class="main-link" v-on:click="stepManual">Prefiro preencher manualmente</span>
    </div>
  </div>
</template>

<script>

import vButton from '../VButton';
import {required} from "vuelidate/lib/validators";

export default {
  name: 'saveStepImport',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        loaded: false,
        user: null,
        password: null,
      },
      save_click: false,
    };
  },

  validations: {
    input: {
      username: {
        required
      },
      password: {
        required
      },
    }
  },

  methods: {
    stepAutomatico() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.username.$error)
            document.getElementById("usernameImport").focus();
          else if (this.$v.input.password.$error)
            document.getElementById("passwordImport").focus();
          return;
        }
        document.activeElement.blur();
        this.$emit('saveStepImport',
            {
              username: this.input.username,
              password: this.input.password,
              idsimulacao: this.simulation.idseg_ac_trabalho,
              nif: this.simulation.nif,
              auto: true
            }
        );
      }
    },

    stepManual() {
      this.loaded = false;
      this.$emit('saveStepImport',
          {
            auto: false
          }
      );
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.background {
  background: #F9FAFB;
  border-radius: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
}

</style>