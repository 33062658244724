<template>
  <div class="col-sm-8 mt-3">
    <img class="img-fluid imagecenter" style="width:110px; margin-top: 10px" src='/assets/images/finish.png'/>
    <h5 class="main-title">{{$t("simulation.reminder.final.title")}}</h5>
    <h5 class="main-final" style="margin-top:16px">{{$t("simulation.reminder.final.description", {day: day, month: month})}}</h5>
    <div class="clearfix" style="text-align: center; margin-top:50px">
      <v-button type="submit" :light="true" style="width:80%" v-on:click="backToWebsite" :text='$t("simulation.back_website")'/>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import vButton from '../VButton';
import { mapGetters  } from 'vuex';

export default {
  name: 'saveStepReminderFinal',
  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },

  components: {
    vButton,
  },
  data() {
    return {
      day: null,
      month: null,
    };
  },

  mounted() {
    this.day =  moment(this.simulation.renovation_date_alert, 'DD / MM / YYYY').subtract(45, "days").format("D");
    this.month =  moment(this.simulation.renovation_date_alert, 'DD / MM / YYYY').subtract(45, "days").format("MMMM");
    window.scrollTo(0,0);
  },
  computed: {
    ...mapGetters({
      website: 'website',
    }),
  },
  methods: {
    backToWebsite() {
      window.location.href = this.website;
    },
  },
};
</script>
