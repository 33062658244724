<template>
  <div class="modal fade"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form v-on:submit.prevent="validate" id="joinForm" novalidate>
          <div class="modal-body custom-p sm">
            <h5 class="main-description">{{ text }}</h5>
          </div>
          <div class="modal-footer justify-content-center">
            <v-button type="submit" :text='$t("simulation.close")'/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import vButton from '../VButton';

export default {
  name: 'SuccessModal',
  components: {
    vButton
  },
  data() {
    return {
      text: null
    }
  },

  methods: {
    openModal(data) {
      this.text = data;
      $(this.$refs.warningModalDIV).modal('show');
    },
    validate() {
      $(this.$refs.warningModalDIV).modal('hide');
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-header,
.modal-footer {
  border:0px;
}

</style>
