<template>
  <div class="mt-3">
    <div class="row justify-content-center">
      <div class="col-sm-8">
        <h5 class="main-title">{{$t("simulation.simulation.title")}}</h5>
        <h5 class="main-description"> {{$t("simulation.simulation.subtitle")}}</h5>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-9">
        <div class="row justify-content-center" style="margin-top:30px">
          <div class="col-sm-7">
            <h5 class="result-value">{{ (simulation.insurance_value.toFixed(simulation.insurance_value % 1 !== 0 ? 2 : 0) + '').replace('.', ',') }}€</h5>
            <h5 class="result-name">{{$t("simulation.simulation.por_ano")}}</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-11">
        <h5 class="main-question" style="height:90px">{{$t("simulation.simulation.question")}}</h5>
        <hr>
        <h5 class="main-description" style="text-align: left">{{$t("simulation.simulation.description")}}</h5>

        <div class="clearfix" style="text-align: center; margin-top:40px">
          <v-button type="submit" :loading="save_click" :text='$t("simulation.buy_insurance")' v-on:click="stepSave"/>
        </div>
        <h5 class="main-description" style="text-align: left; margin-top:16px; margin-bottom:24px">{{$t("simulation.simulation.description2")}}</h5>
        <div class="justify-content-center" style="text-align: center" >
          <span class="main-link" v-on:click="stepReminder">{{$t("simulation.simulation.reminder")}}</span>
        </div>
        <h5 class="main-description-small" style="margin-top:24px">
          <i18n path="simulation.simulation.doubt" tag="label" for="simulation.simulation.click">
            <span v-on:click="stepDoubt" class="main-description-small main-link-small">{{ $t('simulation.simulation.click') }}</span>
          </i18n>
        </h5>
      </div>
    </div>
  </div>
</template>


<script>

import vButton from '../VButton';
import {sendDataLayerInfo} from "@/utils";
import SegTrabalhoTagsEnum from "../../enums/modules/SegTrabalhoTagsEnum";

export default {
  name: 'saveStepSimulation',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        total_persons: null,
        capital: null,
        insurance_value: null,
      },
      save_click: false,
    };
  },

  mounted() {
    if (this.simulation !== null) {
      this.input.total_persons = this.simulation.total_persons;
      this.input.capital = this.simulation.capital;
      this.input.insurance_value = this.simulation.insurance_value;
    }
    window.scrollTo(0,0);
  },
  created() {
    sendDataLayerInfo(SegTrabalhoTagsEnum.PRECO);
  },
  methods: {
    stepSave() {
      if (this.save_click === false) {
        this.save_click = true;

        this.$emit('saveStepSimulation');
      }
    },
    stepReminder() {
      this.$emit('loadStepReminder');
    },
    stepDoubt() {
      this.$emit('saveContact');
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
.result-name {
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;

  text-transform: uppercase;

  /* Black */

  color: #110522;
}

.result-value {
//styleName: Big text;
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 68px;
  font-style: normal;
  font-weight: 800 !important;
  margin-bottom: 0;
  letter-spacing: 0.35px;
  text-align: center;

  /* Black */

  color: #110522;
}
</style>

