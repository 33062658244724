<template>
  <div style="display: block; float:right">
    <div :class="blue ? 'icon-button-blue' : 'icon-button'" style="vertical-align:middle; text-align:center" v-on:click="clicked">
      <img class="divcenter" :src="'/assets/images/' + icon + '.png'"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    loading: Boolean,
    blue: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
  },
  methods: {
    clicked() {
      this.$emit('action', {});
    }
  }
}
</script>

<style scoped>
.icon-button{
  text-align: center;
  width: 30px;
  height: 29px;
  /* Light-grey */

  background: #EEEEEE;
  border-radius: 4.8px;
  cursor: pointer;
  display: inline-block;
}


.icon-button-blue{
  text-align: center;
  width: 37px;
  height: 36px;
  /* Light-grey */
  background: #FDF5C8;
  border-radius: 3px;
  cursor: pointer;
  display:table-cell;
}
button:focus,
button:hover,
.icon-button:focus,
.icon-button:hover,
.icon-button-blue:focus,
.icon-button-blue:hover {
  outline: none;
  border: none;
}

.divcenter {

}
</style>
