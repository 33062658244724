<template>
  <div class="modal" id="loginModal" ref="themodal" tabindex="-1" role="dialog" style="z-index: 2051 !important;" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">DEV Login</h3>
          <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>-->
        </div>
        <div class="modal-body">
          <div class="form-group" :class="{ 'has-error': $v.username.$error }">
            <label class="control-label">Username</label>
            <input class="form-colmena" type="email"
                   v-model.trim="$v.username.$model"
                   :class="{'is-invalid' : $v.username.$error}"
                   @keyup.enter="doLogin"
            />
            <div class="invalid-feedback" v-show="$v.username.$error && !$v.username.required">Required field</div>
          </div>

          <div class="form-group" :class="{ 'has-error': $v.password.$error }">
            <label class="control-label">Password</label>
            <input class="form-colmena" type="password"
                   v-model.trim="$v.password.$model"
                   :class="{'is-invalid' : $v.password.$error}"
                   @keyup.enter="doLogin"
            />
            <div class="invalid-feedback" v-show="$v.password.$error && !$v.password.required">Required field</div>
          </div>

          <div v-show="showError" class="alert alert-danger" role="alert"><span
              v-html="errorMsg"></span></div>
        </div>
        <div class="modal-footer">
          <!--a type="button" class="btn btn-default" :href="recoverPasswordUrl">Forgot Password</a-->
          <v-button type="button" class="btn btn-primary btn-outline" @click.native="doLogin" :loading="loading">Login</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import $ from 'jquery';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import {showSuccess} from "../utils";
import VButton from "./VButton";
import axios from "axios";

Vue.use(Vuelidate);

export default {
  name: "LoginModal",
  props: {
    show: Boolean,
  },
  components: {
    VButton,
  },
  data() {
    return {
      username: null,
      password: null,

      showError: false,
      errorMsg: "",
      loading: false,
    }
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  mounted() {
    let self = this;
    if (this.show) {
      $(this.$refs.themodal).modal('show');
      if (this.profile.email) {
        this.username = this.profile.email;
      } else {
        this.username = null;
      }
      this.password = null;
    } else {
      $(this.$refs.themodal).on('hidden.bs.modal', function () {
        self.setShowLoginModal(false);
      })
    }
  },
  watch: {
    show() {
      if (this.show) {
        $(this.$refs.themodal).modal('show');
        if (this.profile.email) {
          this.username = this.profile.email;
        } else {
          this.username = null;
        }
        this.password = null;
      } else {
        $(this.$refs.themodal).modal('hide');
      }
      this.$v.$reset();
    },
    loginModalError() {
      if (this.loginModalError !== null) {
        this.showError = true;
        this.errorMsg = this.loginModalError;
      } else {
        this.showError = false;
        this.errorMsg = "";
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      loginModalError: 'loginModalError',
    }),/*
            recoverPasswordUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.Signup.forgotPassword().url
            }*/
  },
  methods: {
    ...mapActions({
      setShowLoginModal: 'setShowLoginModal',
    }),
    doLogin() {
      let self = this;
      this.showError = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      const dataObj = {
        username: this.username,
        password: this.password,
      };
      // eslint-disable-next-line
      const loginUrl = jsRoutes.controllers.Application.doLogin().url;
      axios.post(loginUrl, dataObj)
          .then(() => {
            this.loading = false;
            self.setShowLoginModal(false);
            showSuccess("Login Successful")
            // toastr.success('Login Successful');
          })
          .catch(error => {
            console.log("error: " + error);
            self.loading = false;
            // handle error
            this.showError = true;
            this.errorMsg = "Os dados introduzidos não estão corretos. Por favor tente novamente.";
          });
    },
  }

}
</script>

<style scoped>

</style>
