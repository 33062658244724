import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import {i18n} from "@/main";
import VueCookies from 'vue-cookies';

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        website: '',
        language: VueCookies.get("site_language"),
        showLoginModal: window.location.hostname.startsWith('dev'),
        loginModalError: null,

        profile: {}
    },
    getters: {
        profile(state) {
            return state.profile;
        },
        isAdmin(state) {
            return state.profile.securityRoleName === 'admin';
        },
        showLoginModal(state) {
            return state.showLoginModal;
        },
        loginModalError(state) {
            return state.loginModalError;
        },
        website(state) {
            return state.website;
        },
        getLanguage (state) {
            i18n.locale = state.language;
            VueCookies.set('site_language' , state.language);
            return state.language;
        }
    },
    mutations: {
        'SET_PROFILE'(state, payload) {
            state.profile = payload;
        },
        'SHOW_LOGIN_MODAL'(state, show) {
            state.showLoginModal = show;
            state.loginModalError = null;
        },
        'SET_LOGIN_MODAL_ERROR'(state, msg) {
            state.loginModalError = msg;
        },
        'SET_WEBSITE'(state, payload) {
            state.website = payload;
        },
        setLanguage(state, payload) {
            state.language = payload;
        }
    },
    actions: {
        setProfile({commit}, payload) {
            commit('SET_PROFILE', payload);
        },
        loadProfileData({commit}) {
            // eslint-disable-next-line
            let url = jsRoutes.controllers.Account.loadUserProfile().url
            return axios.get(url).then(response => {
                commit('SET_PROFILE', response.data);
                return response.data;
            }, error => {
                throw error;
            })
        },
        setShowLoginModal({commit}, payload) {
            commit('SHOW_LOGIN_MODAL', payload);
        },
        setShowLoginModalExpired({commit}) {
            commit('SHOW_LOGIN_MODAL', true);
            commit('SET_LOGIN_MODAL_ERROR', 'Your session expired. Please login again.');
        },
        setWebsite({commit}, payload) {
            commit('SET_WEBSITE', payload);
        },
        saveLanguage({commit}, payload) {
            commit('setLanguage', payload);
        }
    },
    modules: {}
});
