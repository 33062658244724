<template>
  <div class="input-group date" ref="inputgroup">
    <input type="text" class="form-colmena-picker" v-model="valueTmp" :class="errorClass"
           :placeholder='$t("simulation.start_date.placeholder")'
           readonly
           :style="computedStyle"
           :disabled="disabled"
    >
    <div class="input-group-append hover-hand">
        <span class="input-group-text input-group-text-colmena" :disabled="disabled">
            <!--i class="fa fa-calendar-alt"></i-->
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" class="bi bi-calendar" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
          </svg>
        </span>
    </div>
    <!--        <span class="input-group-addon"><i class="fa fa-calendar-alt"></i></span>-->
  </div>
</template>
<style scoped>
.hover-hand:hover {
  cursor: pointer;
}
</style>
<script>
import $ from 'jquery'
import moment from 'moment';
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt.min'

moment.locale('pt')

export default {
  props: {
    value: {
      default() {
        return '';
      }
    },
    startDate: {
      required: false,
      default() {
        return null;
      }
    },
    endDate: {
      required: false,
      default() {
        return null;
      }
    },
    disabled: {
      required: false,
      default() {
        return false;
      }
    },
    errorClass: {
      required: false,
      default() {
        return '';
      }
    },


  },
  data: function () {
    return {
      valueTmp: null,
    };
  },
  watch: {
    value: function () {
      this.valueTmp = this.value; // so serve para mostrar o valor inicial
      // console.log("value => " + this.value)
      this.datepicker.datepicker("update", this.value);
      this.$emit('change');
    },
  },
  computed: {
    computedStyle() {
      return this.disabled ? "" : ""
    }
  },
  mounted: function () {
    const self = this;
    this.valueTmp = this.value;
    const options = {
      format: 'dd / mm / yyyy',
      todayHighlight: true,
      // minViewMode: 1,
      language: "pt",
      autoclose: true,
    }

    if(this.startDate!= null){
      options.startDate= this.startDate
    }
    if(this.endDate!= null){
      options.endDate= this.endDate
    }

    this.datepicker = $(this.$refs.inputgroup).datepicker(options)
        .on("changeDate", function () {
          // eslint-disable-next-line
          self.$emit('update:value', moment(self.datepicker.datepicker("getDate")).format('DD / MM / YYYY'));
          // self.$emit('change');
        });
    this.datepicker.datepicker("update", this.value);
  }
}
</script>
