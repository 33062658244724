<template>
  <div class="d-flex">
    <button v-for="(o, index) in options" type="button" :class="[{ 'radioselected': selected === o.value }, { 'radiounselected': selected !== o.value },
    { 'disabled': o.disabled },
    {'widgetmargin': index !== (options.length - 1)}]" :style="minwidth"
            :key="o.value" v-show="!o.disabled" v-on:click="setRadioOption(o.value, o.disabled)">{{ o.text }}</button>
  </div>
</template>
<script>

export default {
  props: {
    minwidth: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
    }
  },

  methods: {
    setRadioOption(value, disabled) {
      if (!disabled) {
        this.$emit('setRadioOption',
            {
              input: value,
            }
        );
      }
    },
  },

}
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
.disabled {
  cursor: default;
}
.radioselected,
.radiounselected {
  min-width: 135px;
  min-height: 45px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: center;
  letter-spacing: 0.5px;
  border-radius: 8px;
  text-transform: uppercase;

}
.radioselected {
  font-weight: bold;
  font-size: 14px;
  color: #110522;
  border: 2px solid #FBDE23;
  background: white;
}

.radiounselected{
  font-size: 12px;
  /* Grey */
  color: #676767;
  background: #F9FAFB;
  border: 2px solid #F9FAFB;
}

.radioselected:focus,
.radiounselected:focus {
  outline:none;
}

.widgetmargin {
  margin-right: 8px;
}
</style>
