<template>
  <div class="col-sm-12 mt-3">
    <img class="img-fluid imagecenter" style="width:110px; margin-top: 10px" src='/assets/images/finish.png'/>
    <h5 class="main-title" style="margin-top:16px">{{$t("simulation.final.title")}}</h5>
    <h5 class="main-final" style="margin-top:16px"> {{$t("simulation.final.description")}}</h5>

    <div class="clearfix" style="text-align: center; margin-top:50px">
      <v-button type="submit" :light="true" style="width:80%" v-on:click="backToWebsite" :text='$t("simulation.back_website")'/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import vButton from '../VButton';

export default {
  name: 'saveStepFinal',

  components: {
    vButton,
  },
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters({
      website: 'website',
    }),
  },
  methods: {
    backToWebsite() {
      window.location.href = this.website;
    },
  },
};
</script>
