<template>
  <div class="mt-3">
    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <div class="justify-content-center">
        <div class="col-sm-12">
          <h5 class="main-title">{{$t("simulation.start.title")}}</h5>

          <h5 class="main-description"> {{$t("simulation.start.description")}}</h5>

          <hr>
          <div class="form-group form-margin" :class="{ 'has-error': $v.input.email.$error }">
            <label for="emailStart" class="control-label">{{$t("simulation.email")}}</label>
            <input class="form-colmena" type="text" name="email" id="emailStart" :placeholder='$t("simulation.email.placeholder")'
                   :class="{'is-invalid' : $v.input.email.$error}"
                   v-model.trim="$v.input.email.$model"/>
            <span class="invalid-feedback" v-show="$v.input.email.$error && !$v.input.email.required">
                                {{$t("simulation.required")}}</span>
            <span class="invalid-feedback" v-show="$v.input.email.$error && $v.input.email.required && !$v.input.email.maxLength">
                                {{$t("simulation.maxLength", { max: "255"})}}</span>
            <span class="invalid-feedback" v-show="$v.input.email.$error && $v.input.email.required && $v.input.email.maxLength && (!$v.input.email.email || !$v.input.email.isValidEmail)">
                                {{$t("simulation.email_valid")}}</span>
          </div>
          <h5 class="main-description" style="margin:24px 0px 14px 0px; text-align:left"> {{$t("simulation.start.description2")}}</h5>

          <div class="form-group" :class="{ 'has-error': $v.input.nif.$error }">
            <label for="nifStart" class="control-label">{{$t("simulation.nif")}}</label>
            <input class="form-colmena" type="text" name="nifStart" id="nifStart" :placeholder='$t("simulation.nif.placeholder")'
                   :class="{'is-invalid' : $v.input.nif.$error}"
                   v-model.trim="$v.input.nif.$model"/>
            <span class="invalid-feedback" v-show="$v.input.nif.$error && !$v.input.nif.required">
                                {{$t("simulation.required")}}</span>
            <span class="invalid-feedback" v-show="$v.input.nif.$error && $v.input.nif.required && !$v.input.nif.isValidNIF">
                                {{$t("simulation.number_invalid_format")}}</span>
          </div>

          <!--h5 class="main-description"> {{$t("simulation.start.terms")}}<br/><a href="#" style="color:#6600FF">{{$t("simulation.start.terms2")}}</a></h5-->

          <div class="clearfix" style="text-align: center; margin-top:32px">
            <v-button type="submit" :loading="save_click" :text='$t("simulation.get_price")'/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.error {
  border: red 1px solid;
}
</style>

<script>
import {required, maxLength, email} from 'vuelidate/lib/validators';

import vButton from '../VButton';

function validNIF(nif) {
  if (nif.length === 9) {
    if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
        !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2)))
      return false;

    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;

    let modulo11 = total - parseInt(total / 11) * 11;
    let comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;

    return parseInt(nif[8]) === parseInt(comparador);
  } else {
    return false;
  }
}

function validEmail(email) {
  return email.match("^([a-zA-Z0-9_.\\-+])+@(([a-zA-Z0-9\\-])+\\.)+[a-zA-Z0-9]{2,4}$") != null;
}

export default {
  name: 'saveStepStart',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        email: null,
        nif: null,
      },
      save_click: false,
    };
  },
  validations: {
    input: {
      email: {
        required,
        email,
        maxLength: maxLength(255),
        isValidEmail(value) {
          if (value === null || value === '') return false;
          return validEmail(value);
        }
      },
      nif: {
        required,
        isValidNIF(value) {
          if (value === null || value === '') return false;
          return validNIF(value);
        },
      },
    }
  },

  mounted() {
    if (this.simulation !== null) {
      this.input.email = this.simulation.email;
      this.input.nif = this.simulation.nif;
    }
  },
  methods: {
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.email.$error)
            document.getElementById("emailStart").focus();
          else if (this.$v.input.nif.$error)
            document.getElementById("nifStart").focus();
          return;
        }
        document.activeElement.blur();
        if (this.input.nif.startsWith("1") || this.input.nif.startsWith("2") || this.input.nif.startsWith("3")) {
          this.enableButton();
          window.open("/" + this.$route.params.lang + "/seguroatindependente/1", "_self");
        } else {
          this.$emit('saveStepStart',
              {
                input: this.input,
              }
          );
        }
      }
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>
