<template>
  <button class="colmena-button" :class="[{'full-width' : full}, {'colmena-button-light' : light}, {'colmena-button-green' : green}, {'colmena-button-small': small}]" :disabled="loading" @click="$emit('click', $event)">
    <slot v-if="!loading"><span> {{text}}</span></slot>
    <span class="padding-right" v-else><i class="fa fa-spinner fa-spin"></i> {{text}}</span>
  </button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    loading: Boolean,
    small: {
      type:Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.padding-right {
  padding-right:25px;
}
.colmena-button,
.colmena-button:hover,
.colmena-button:focus {
  min-width: 355px;
  min-height: 70px;
  font-size: 20px;
  margin-bottom:24px;
}

button:focus,
button:hover {
  outline: none;
  border: none;
}

.full-width {
  width: 100%;
  text-transform: none;
}
input[type=checkbox]:checked:before {
  color: black;
}

.colmena-button-light,
.colmena-button-light:focus,
.colmena-button-light:hover {
  min-height: 62px;
  background-color: #FDF5C8;

//styleName: button;
  font-family: Gilroy, Gilroy ExtraBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #110522;
  border: 0px;
}
.colmena-button-light:focus,
.colmena-button-light:hover {
  outline: none;
}

.colmena-button-green,
.colmena-button-green:focus,
.colmena-button-green:hover {
  background: #408F4C;
  border-radius: 10px;

  font-weight: bold;
  text-transform: uppercase;
  color: white;
}
.colmena-button-green:focus,
.colmena-button-green:hover {
  outline: none;
  border: 0;
}

.colmena-button-small,
.colmena-button-small:focus,
.colmena-button-small:hover {
  min-height: 32px;
  min-width: 130px;
  font-size: 14px;
}
.colmena-button-small:focus,
.colmena-button-small:hover {
  outline: none;
  border: 0;
}

</style>
