<template>
  <div class="modal fade"
       tabindex="-1"
       data-keyboard="false" data-backdrop="static"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body custom-p sm">
          <h5 class="main-description">{{ text }}</h5>

          <div class="clearfix" style="text-align: center; margin-top:18px">
            <v-button v-on:click="stepContact" :text='$t("simulation.contact_me")'/>
          </div>

          <div class="clearfix" style="text-align: center; margin-top:18px">
            <span class="main-link" style="margin-top: 16px" v-on:click="closeModal">{{$t("simulation.payment.backStep")}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import vButton from '../VButton';
import {SegTrabalhoStepsEnum} from "@/enums";
import axios from "axios";
import {handleAjaxError} from "@/utils";

export default {
  name: 'CapitalErrorModal',
  components: {
    vButton
  },
  data() {
    return {
      text: null,
      simulacao: null,
      save_click: false,
    }
  },

  methods: {
    openModal(text, data) {
      this.text = text;
      this.simulacao = data;
      $(this.$refs.warningModalDIV).modal('show');
    },
    stepContact() {
      if (this.simulacao.status === SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER || this.simulacao.status === SegTrabalhoStepsEnum.INICIO) {
        const self = this;
        self.simulacao.status = SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER;
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.saveNewStatusNoQualif().url;
        // GET request
        axios.post(url, self.simulacao)
            .then(function (/*response*/) {
              self.closeModal()
              self.$emit('sendContact');
            }).catch(function (error) {
          // handle error
          handleAjaxError(error);
        });
      } else {
        const self = this;
        self.simulacao.status = SegTrabalhoStepsEnum.COMPRAR_DADOS_SALARY_HIGHER;
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.saveNewStatusNoQualif().url;
        // GET request
        axios.post(url, self.simulacao)
            .then(function (/*response*/) {
              self.closeModal()
              self.$emit('sendContact');
            }).catch(function (error) {
          // handle error
          handleAjaxError(error);
        });
      }
    },
    closeModal() {
      $(this.$refs.warningModalDIV).modal('hide');
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-header,
.modal-footer {
  border:0px;
}

</style>
