<template>
  <div class="col-sm-12">
    <h5 class="main-title">{{$t("simulation.404")}}</h5>
  </div>
</template>

<style scoped>
.error {
  border: red 1px solid;
}
</style>

<script>
export default {
  name: '404',

  props: {},
  data() {
    return {
    };
  },
};
</script>
