<template>
  <div class="mt-4">
    <data-confirmation-modal ref="dataConfirmationModal" @loadStepBack="loadStepBack" @loadStepSave="loadStepSave"></data-confirmation-modal>
    <form v-on:submit.prevent="validate" id="joinForm1" novalidate>
      <div class="col-sm-11">
        <div class="row justify-content-center">
          <div class="col-sm-12">
            <h5 class="main-step">{{$t("simulation.step", {step:3, total:3})}}</h5>

            <h5 class="main-question-qualif" v-show="simulation.premio !== 'Fixo'">{{ $t("simulation.payment.variavel.title")}}</h5>
            <h5 class="main-title" v-show="simulation.premio === 'Fixo'">{{$t("simulation.payment.fixo.title")}}</h5>
            <h5 class="main-description" v-show="simulation.premio === 'Fixo'">{{simulation.loaded_hapi ? $t("simulation.payment.fixo.description.loaded") : $t("simulation.payment.fixo.description")}}</h5>
          </div>
        </div>
        <div class="row" style="margin-top: 46px" v-if="simulation.premio !== 'Fixo'">
          <div class="col-sm-6">
            <div class="form-group" :class="{ 'has-error': $v.input.total_persons.$error }">
              <label for="total_personsPayment" class="control-label">{{$t("simulation.total_persons")}}</label>
              <input class="form-colmena" type="text" name="total_personsPayment" id="total_personsPayment" :placeholder='$t("simulation.total_persons.placeholder")'
                     :class="{'is-invalid' : $v.input.total_persons.$error}"
                     v-model.trim="$v.input.total_persons.$model"/>
              <span class="invalid-feedback" v-show="$v.input.total_persons.$error && !$v.input.total_persons.required">
                                {{$t("simulation.required")}}</span>
              <span class="invalid-feedback" v-show="$v.input.total_persons.$error && !$v.input.total_persons.integer">
                                {{$t("simulation.integer")}}</span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" :class="{ 'has-error': $v.input.capital.$error }">
              <label for="capitalPayment" class="control-label">{{$t("simulation.capital")}}</label>
              <input class="form-colmena" type="text" name="capitalPayment" id="capitalPayment" :placeholder='$t("simulation.capital.placeholder")'
                     :class="{'is-invalid' : $v.input.capital.$error}"
                     v-model.trim="capitalInput"/>
              <span class="invalid-feedback" v-show="$v.input.capital.$error && !$v.input.capital.required">
                                {{$t("simulation.required")}}</span>
              <span class="invalid-feedback" v-show="$v.input.capital.$error && !$v.input.capital.isDecimal">
                                {{$t("simulation.number_invalid_format")}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="pt-3" v-if="simulation.premio === 'Fixo'" style="margin-bottom: 32px">
      <div class="table-responsive">
        <div class="tableWrap" ref="tableScroll">
          <table class="table table-striped">
            <thead>
            <tr>
              <th style="width:239px">{{$t("simulation.name")}}</th>
              <th style="width:200px">{{$t("simulation.task")}}</th>
              <th style="width:144px">{{$t("simulation.anual_salary")}}</th>
              <th style="width: 101px; text-align: right">
                <icon-button :blue="true" icon="plus" @action="addEmployeeButton()"  style="margin-right:8px"></icon-button>
              </th>
            </tr>
            </thead>

            <tbody>
            <simulation-step-payment-item :ref="'SimulationItem' + index" :item="item" :index="index" v-for="(item, index) in input.employees" :key="index"
                                          @deleteEvent="deleteEmployee"  @addEvent="addEmployee" @closeOthersEvent="closeOtherEmployeesAndEdit" @doScrollEvent="doScroll"/>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <div class="col-sm-11">
        <div class="form-group" :class="{ 'has-error': $v.input.payment.$error }" style="margin-bottom:34px">
          <div class="row">
            <div class="col-sm-12" :class="{'is-invalid' : $v.input.payment.$error}">
              <!--span class="control-label-alt" style="margin-top:16px;margin-bottom:24px">{{$t("simulation.payment.message1")}}</span>
              <label for="paymentPayment" class="control-label-small" style="margin-bottom:24px">{{$t("simulation.payment.message2")}}</label-->
              <label for="paymentPayment" class="control-label-alt" style="margin-bottom:24px">{{$t("simulation.payment.message1")}}</label>
              <radio-buttons-spacing id="paymentPayment" name="paymentPayment" :options="payment_options" :minwidth='{"min-width": "145px"}'  :selected="input.payment" @setRadioOption="setPaymentOption"></radio-buttons-spacing>
              <span class="invalid-feedback" :class="{'validate-failed' : $v.input.payment.$error}" v-show="$v.input.payment.$error && !$v.input.payment.required">{{$t("simulation.required")}}</span>
              <span class="invalid-feedback" :class="{'validate-failed' : $v.input.payment.$error}" v-show="$v.input.payment.$error && $v.input.payment.required && !$v.input.payment.checkValidPayment">{{$t("simulation.invalid.payment")}}</span>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-group" :class="{ 'has-error': $v.input.payment_type.$error }">
          <div class="row">
            <div class="col-sm-12" :class="{'is-invalid' : $v.input.payment_type.$error}">
              <label for="payment_typePayment" class="control-label-alt" style="margin-top:0px;margin-bottom:14px">{{$t("simulation.payment_method")}}</label>
              <radio-buttons-spacing ref="PaymentTypeRadio" id="payment_typePayment" name="payment_typePayment" :options="payment_type_options" :minwidth='{"min-width": "181px"}'  :selected="input.payment_type" @setRadioOption="setPaymentTypeOption"></radio-buttons-spacing>
              <span class="invalid-feedback" :class="{'validate-failed' : $v.input.payment_type.$error}" v-show="$v.input.payment_type.$error && !$v.input.payment_type.required">{{$t("simulation.required")}}</span>
            </div>
          </div>
        </div>

        <div class="form-group" :class="{ 'has-error': $v.input.iban.$error }" v-show="input.payment_type === 'DEB. DIRETO'">
          <input class="form-colmena" type="text" name="ibanPayment" id="ibanPayment" :placeholder='$t("simulation.iban")'
                 :class="{'is-invalid' : $v.input.iban.$error}"
                 v-model.trim="$v.input.iban.$model"/>
          <span class="invalid-feedback" v-show="$v.input.iban.$error && !$v.input.iban.required">
                                {{$t("simulation.required")}}</span>
          <span class="invalid-feedback" v-show="$v.input.iban.$error && !$v.input.iban.isValidIBAN">
                                {{$t("simulation.number_invalid_format")}}</span>
        </div>

        <div class="clearfix" style="text-align: center; margin-top:44px">
          <v-button type="submit" :loading="save_click" :text='$t("simulation.emit_apolice")'/>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-9" :class="{'is-invalid' : $v.input.checked.$error}">

            <label class="checkbox-container control-label-small" style="margin-left:16px">{{$t("simulation.payment.validation")}}
              <input type="checkbox" checked="checked" v-model.trim="$v.input.checked.$model">
              <span class="checkmark"></span>
            </label>
            <span class="invalid-feedback" :class="{'validate-failed' : $v.input.checked.$error}" v-show="$v.input.checked.$error">{{$t("simulation.required")}}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
const IBANValidation = require('iban');

import {required, requiredIf} from 'vuelidate/lib/validators';

import vButton from '../VButton';
import RadioButtonsSpacing from '../RadioButtonsSpacing';
import IconButton from "@/components/IconButton";
import SimulationStepPaymentItem from "./SimulationStepPaymentItem";
import DataConfirmationModal from "@/components/modal/DataConfirmationModal";
import {sendDataLayerInfo} from "@/utils";
import {SegTrabalhoTagsEnum} from "@/enums";

const checkSelected = (value) => {
  return value === true;
}

const checkValidPayment = (value, premio) => {
  if (value === 'Anual') {
    return true;
  }
  if (value === 'Semestral') {
    return parseFloat(premio) >= 60.00;
  }
  if (value === 'Trimestral') {
    return parseFloat(premio) >= 120.00;
  }
  if (value === 'Mensal') {
    return parseFloat(premio) >= 360.00;
  }
  return false;
}

/*const isValidIBANNumber = (input) => {
  var CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
    SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };
  var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}

function mod97(string) {
  var checksum = string.slice(0, 2), fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}*/


const isDecimalNumber = (input) => {
  var v = (input + '').replace(',', '.');

  // final check
  return v === (parseFloat(v) + '') ||
      v === (parseFloat(v).toFixed(1) + '') ||
      v === (parseFloat(v).toFixed(2) + '');
}


export default {
  name: 'SimulationStepPayment',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
    RadioButtonsSpacing,
    IconButton,
    SimulationStepPaymentItem,
    DataConfirmationModal
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        total_persons: null,
        capital: null,
        payment: null,
        payment_type: null,
        premio: null,
        insurance_value:null,
        checked: null,
        iban: null,
        employees: []
      },
      set_capital: false,
      save_click: false,
      payment_options: [],
      payment_type_options: []
    };
  },
  validations: {
    input: {
      total_persons: {
        required: requiredIf(function() {
          return this.simulation.premio !== 'Fixo';
        })
      },
      capital: {
        required: requiredIf(function() {
          return this.simulation.premio !== 'Fixo';
        }),
        isDecimal(value) {
          if (value === null || value === '') return true;
          this.input.capital = (this.input.capital + '').replace(',', '.');
          return isDecimalNumber(value);
        },
      },
      payment: {
        required,
        checkValidPayment(value) {
          return checkValidPayment(value, this.simulation.insurance_value);
        },
      },
      payment_type: {
        required
      },
      checked: {
        required,
        checkSelected(value) {
          return checkSelected(value);
        },
      },
      iban: {
        required: requiredIf(function() {
          return this.input.payment_type === 'DEB. DIRETO';
        }),
        isValidIBAN(value) {
          if (value === null || value === '') return true;
          return IBANValidation.isValid(value);
        },
      }
    }
  },
  watch: {
    'input.payment' : function() {
      this.payment_type_options = [
        {text: this.$t('simulation.payment.type.debito'), value: 'DEB. DIRETO', disabled: false},
        {text: this.$t('simulation.payment.type.service'), value: 'PAG. SERVIÇOS', disabled: this.input.payment === 'Mensal'},
        {text: this.$t('simulation.payment.type.transf'), value: 'TRANSF. BANCARIA', disabled: this.input.payment === 'Mensal'},
      ];
    }
  },
  computed: {
    capitalInput: {
      // getter
      get: function() {
        if (this.input.capital !== null && this.input.capital !== "" && (this.input.capital + '').indexOf('.') > -1 && !(this.input.capital + '').indexOf('.00') > -1) {
          if (!this.set_capital)
            return (parseFloat(this.input.capital).toFixed(2) + '').replace('.', ',');
          else
            return this.input.capital.replace('.', ',');
        }
        return this.input.capital;
      },
      // setter
      set: function(newValue) {
        this.set_capital = true;
        this.input.capital = newValue.replace(',', '.');
      }
    }
  },

  mounted() {
    if (this.simulation !== null) {
      this.input.capital = this.simulation.capital;
      this.input.total_persons = this.simulation.total_persons;
      this.input.employees = this.simulation.employees;
    }
    this.payment_options = [
      { text: this.$t('simulation.period.anual'), value: 'Anual', disabled: false },
      { text: this.$t('simulation.period.semestral'), value: 'Semestral', disabled: parseFloat(this.simulation.insurance_value) < 60.0 },
      { text: this.$t('simulation.period.trimestral'),  value: 'Trimestral', disabled: parseFloat(this.simulation.insurance_value) < 120.0 },
      { text: this.$t('simulation.period.mensal'),  value: 'Mensal', disabled: parseFloat(this.simulation.insurance_value) < 360.0 },
    ];
    this.payment_type_options = [
      { text: this.$t('simulation.payment.type.debito'), value: 'DEB. DIRETO', disabled: false },
      { text: this.$t('simulation.payment.type.service'), value: 'PAG. SERVIÇOS', disabled: this.input.payment === 'Mensal'},
      { text: this.$t('simulation.payment.type.transf'), value: 'TRANSF. BANCARIA', disabled: this.input.payment === 'Mensal' },
    ];

    this.set_capital = false;
    window.scrollTo(0,0);
    this.save_click = false;
  },
  created() {
    if (this.simulation.premio === 'Fixo')
      sendDataLayerInfo(SegTrabalhoTagsEnum.DADOS_EMISSAO_FIXO)
    else
      sendDataLayerInfo(SegTrabalhoTagsEnum.DADOS_EMISSAO_VARIAVEL)
  },
  methods: {
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        let valid = true;
        if (this.simulation.premio === 'Fixo') {
          for (let n = 0; n < this.input.employees.length; n++) {
            let id = 'SimulationItem' + n;
            valid = this.$refs[id][0].validate(n === this.input.employees.length - 1) && valid;
          }
        }
        if (this.$v.$invalid || !valid) {
          this.save_click = false;
          if (this.$v.input.total_persons.$error)
            document.getElementById("total_personsPayment").focus();
          else if (this.$v.input.capital.$error)
            document.getElementById("capitalPayment").focus();
          else if (this.$v.input.payment_type.$error)
            document.getElementById("payment_typePayment").focus();
          else if (this.$v.input.payment.$error)
            document.getElementById("paymentPayment").focus();
          else if (this.$v.input.iban.$error)
            document.getElementById("ibanPayment").focus();
          return;
        }

        if (this.simulation.premio === 'Fixo' && this.input.employees.length > 0) {
          let id2 = 'SimulationItem' + (this.input.employees.length - 1);
          if (this.$refs[id2][0].isEmpty()) {
            this.input.employees.splice((this.input.employees.length - 1), 1);
          }
        }
        document.activeElement.blur();
        if (this.simulation.premio === 'Fixo') {

          let sumSalary = this.input.employees.reduce((acc, item) => acc + parseFloat((item.salary + '').replace(',', '.')), 0);
          if (parseFloat(sumSalary) > 500000) {
            this.setCapitalError();
          } else {
            if ((this.simulation.total_persons !== this.input.employees.length) || parseFloat(this.simulation.capital) !== (sumSalary)) {

              this.$refs.dataConfirmationModal.openModal(
                  {
                    text: this.$t("simulation.payment.confirmation",
                        {
                          persons: this.simulation.total_persons,
                          capital: ((this.simulation.capital + '').indexOf('.') > -1 ? ((parseFloat(this.simulation.capital).toFixed(2) + '').replace('.', ',')) : this.simulation.capital) + ' €'
                        }
                    ),
                    total_persons: this.simulation.total_persons,
                    capital: this.simulation.capital,
                    company_name: this.simulation.company_name,
                    cae: this.simulation.cae,
                    nif: this.simulation.nif,
                    new_total_persons: this.input.employees.length,
                    new_capital: sumSalary,
                  });
            } else {
              this.saveStep();
            }
          }
        } else {
          if (parseFloat(this.input.capital) > 500000) {
            this.setCapitalError();
          } else {
            if ((this.input.total_persons !== this.simulation.total_persons) || (parseFloat(this.input.capital) !== parseFloat(this.simulation.capital))) {
              this.$refs.dataConfirmationModal.openModal(
                  {
                    text: this.$t("simulation.payment.confirmation",
                        {
                          persons: this.simulation.total_persons,
                          capital: ((this.simulation.capital + '').indexOf('.') > -1 ? ((parseFloat(this.simulation.capital).toFixed(2) + '').replace('.', ',')) : this.simulation.capital) + ' €'
                        }
                    ),
                    total_persons: this.simulation.total_persons,
                    capital: this.simulation.capital,
                    company_name: this.simulation.company_name,
                    cae: this.simulation.cae,
                    nif: this.simulation.nif,
                    new_total_persons: this.input.total_persons,
                    new_capital: this.input.capital,
                  });
            } else {
              this.saveStep();
            }
          }
        }
      }
    },
    saveStep() {
      this.$emit('saveStepPayment',
          {
            input: this.input,
          }
      );
    },
    loadStepBack() {
      this.enableButton();
      this.$refs.dataConfirmationModal.closeModal();
    },
    loadStepSave(val) {
      this.set_capital = false;
      this.enableButton();
      this.simulation.total_persons = val.new_total_persons;
      this.simulation.capital = val.new_capital;
      this.simulation.premio = val.premio;
      this.simulation.insurance_value = val.insurance_value;
      this.input.total_persons = val.new_total_persons;
      this.input.capital = val.new_capital;
      this.input.premio = val.premio;
      this.input.insurance_value = val.insurance_value;

      this.$refs.dataConfirmationModal.closeModal();
    },
    setPaymentOption(val) {
      this.input.payment = val.input;
      if (this.input.payment === 'Mensal') {
        this.input.payment_type = 'DEB. DIRETO';
      }
    },
    setPaymentTypeOption(val) {
      if (this.input.payment === null || this.input.payment !== 'Mensal') {
        this.input.payment_type = val.input;
      }
    },
    enableButton() {
      this.save_click = false;
    },
    deleteEmployee(data) {
      let valid = this.closeOpenedEditsIfValid(data);
      if (valid) {
        for (let n = 0; n < this.input.employees.length; n++) {
          var id2 = 'SimulationItem' + n;
          this.$refs[id2][0].closeEdit();
        }
        this.input.employees.splice(data.index, 1);
      }
    },
    closeOtherEmployeesAndEdit(data) {
      let valid = this.closeOpenedEditsIfValid(data);
      if (valid) {
        for (let n = 0; n < this.input.employees.length; n++) {
          if (n === data.index) {
            var id2 = 'SimulationItem' + n;
            this.$refs[id2][0].allowEdit();
          }
        }
      }
    },
    addEmployee() {
      var val = {
        name: null,
        task: null,
        salary: null,
        idemployee: null,
        doScroll: true,
      };
      for (let n = 0; n < this.input.employees.length; n++) {
        var id = 'SimulationItem' + n;
        this.$refs[id][0].closeEdit();
      }
      this.input.employees.splice(this.input.employees.length, 0, val);
    },
    addEmployeeButton() {
      let valid = true;
      for (let n = 0; n < this.input.employees.length; n++) {
        var id = 'SimulationItem' + n;
        valid = this.$refs[id][0].validate(false) && valid;
      }

      if (valid)
        this.addEmployee();
    },
    closeOpenedEditsIfValid(data) {
      let valid = true;
      for (let n = 0; n < this.input.employees.length; n++) {
        if (n !== data.index) {
          var id = 'SimulationItem' + n;
          var thisValid = this.$refs[id][0].validate(n === this.input.employees.length - 1);
          valid = thisValid && valid;
          if (thisValid) {
            this.$refs[id][0].closeEdit();
          }
        }
      }
      return valid;
    },
    setCapitalError() {
      this.$emit('showCapitalError');
      this.enableButton();
    },
    doScroll() {
      this.$refs.tableScroll.scrollTo(0, 100000);
    }
  },
};
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
/* Set a fixed scrollable wrapper */
.tableWrap {
  max-height: 600px;
  overflow: auto;
  scrollbar-width: thin;
}
/* Set header to stick to the top of the container. */
thead tr th {
  position: sticky;
  top: 0;
}

/* If we use border,
we must use table-collapse to avoid
a slight movement of the header row */
table {
  border-collapse: collapse;
}

/* Because we must set sticky on th,
 we have to apply background styles here
 rather than on thead */
th {
  background: white;
  text-align: left;
  box-shadow: 1px 0 0 1px #e8e8e8;
}
</style>