const SegTrabalhoTagsEnum = Object.freeze({
    TAXA: {
        TEXT: "Viu a taxa",
        URL: "?page#taxa",
    },
    CAE_INEXISTENTE_FORM: {
        TEXT: "Não temos taxa para CAE - Form",
        URL: "?page#form-cae-inexistente",
    },
    CAE_INEXISTENTE_ENVIADO: {
        TEXT: "Não temos taxa para CAE - Form Enviado",
        URL: "?page#form-cae-inexistente-enviado",
    },
    NIF_INDIVIDUAL_FORM: {
        TEXT: "Não temos taxa para NIF individual - Form",
        URL: "?page#form-nif-individual",
    },
    NIF_INDIVIDUAL_ENVIADO: {
        TEXT: "Não temos taxa para NIF individual - Form Enviado",
        URL: "?page#form-nif-individual-enviado",
    },
    OUTROS_PEDIDOS_FORM: {
        TEXT: "Outros pedidos - Form",
        URL: "?page#form-outros-pedidos",
    },
    OUTROS_PEDIDOS_ENVIADO: {
        TEXT: "Outros pedidos - Enviado",
        URL: "?page#form-outros-pedidos-enviado",
    },
    PRECO: {
        TEXT: "Viu o preço",
        URL: "?page#preco",
    },
    LEMBRETE_FORM: {
        TEXT: "Quer ser avisado - Form",
        URL: "?page#form-lembrete",
    },
    LEMBRETE_ENVIADO: {
        TEXT: "Quer ser avisado - Enviado",
        URL: "?page#form-lembrete-enviado",
    },
    QUESTOES_ADICIONAIS_FORM: {
        TEXT: "Questões adicionais - Form",
        URL: "?page#form-questoes",
    },
    QUESTOES_ADICIONAIS_ENVIADO: {
        TEXT: "Questões adicionais - Enviado",
        URL: "?page#form-questoes-enviado",
    },
    EXCLUSOES: {
        TEXT: "Viu os critérios de exclusão",
        URL: "?page#exclusoes"
    },
    EXCLUSOES_FORM: {
        TEXT: "Caiu nas exclusões - Form",
        URL: "?page#form-exclusoes",
    },
    EXCLUSOES_ENVIADO: {
        TEXT: "Caiu nas exclusões  - Enviado",
        URL: "?page#form-exclusoes-enviado",
    },
    DADOS: {
        TEXT: "Viu ecrã de validação de dados",
        URL: "?page#dados",
    },
    DADOS_LEMBRETE_FORM: {
        TEXT: "Quer ser avisado - Ecrã de Dados - Form",
        URL: "?page#form-lembrete-2",
    },
    DADOS_LEMBRETE_ENVIADO: {
        TEXT: "Quer ser avisado - Ecrã de Dados - Enviado",
        URL: "?page#form-lembrete-enviado-2",
    },
    DADOS_EMISSAO_FIXO: {
        TEXT: "Emissão prémio fixo - Viu ecrã para inserção de colaboradores",
        URL: "?page#premio-fixo",
    },
    DADOS_EMISSAO_VARIAVEL: {
        TEXT: "Emissão prémio variável - Viu ecrã para confirmação de capital",
        URL: "?page#premio-variável",
    },
    NOVA_SIMULACAO_FIXO: {
        TEXT: "Emissão prémio fixo - Viu novo preço",
        URL: "?page#premio-fixo-novos-valores",
    },
    NOVA_SIMULACAO_VARIAVEL: {
        TEXT: "Emissão prémio variável - Viu novo preço",
        URL: "?page#premio-variável-novos-valores",
    },
    EMISSAO_APOLICE_FIXO: {
        TEXT: "Pediu emissão de apólice de prémio fixo",
        URL: "?page#emitido-premio-fixo",
    },
    EMISSAO_APOLICE_VARIAVEL: {
        TEXT: "Pediu emissão de apólice de prémio variável",
        URL: "?page#emitido-premio-variavel",
    },
//    Viu a taxa depois de inserir CAE	?page#taxa-CAE-manual	Viu a taxa CAE manual
});

export default SegTrabalhoTagsEnum;