<template>
  <div class="mt-3">
    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <div class="row justify-content-center">
        <div class="col-sm-11">
          <h5 class="main-title">{{$t(titleText)}}</h5>
          <h5 class="main-description" style="margin-bottom: 16px">{{$t(subtitleText)}}</h5>

          <div class="form-group" :class="{ 'has-error': $v.input.contact_name.$error }">
            <label for="contact_nameContact" class="control-label">{{$t("simulation.name")}}</label>
            <input class="form-colmena" type="text" name="contact_nameContact" id="contact_nameContact" :placeholder='$t("simulation.contact_name.placeholder")'
                   :class="{'is-invalid' : $v.input.contact_name.$error}"
                   v-model.trim="$v.input.contact_name.$model"/>
            <span class="invalid-feedback" v-show="$v.input.contact_name.$error && !$v.input.contact_name.required">
                                {{$t("simulation.required")}}</span>
          </div>
          <div class="form-group" :class="{ 'has-error': $v.input.contact_email.$error }">
            <label for="contact_emailContact" class="control-label">{{$t("simulation.email")}}</label>
            <input class="form-colmena" type="text" name="contact_emailContact" id="contact_emailContact" :placeholder='$t("simulation.email.placeholder")'
                   :class="{'is-invalid' : $v.input.contact_email.$error}"
                   v-model.trim="$v.input.contact_email.$model"/>
            <span class="invalid-feedback" v-show="$v.input.contact_email.$error && !$v.input.contact_email.required">
                                {{$t("simulation.required")}}</span>
            <span class="invalid-feedback" v-show="$v.input.contact_email.$error && !$v.input.contact_email.email">
                                {{$t("simulation.email_valid")}}</span>
          </div>
          <div class="form-group" :class="{ 'has-error': $v.input.phone.$error }">
            <label for="phoneContact" class="control-label">{{$t("simulation.phone")}}</label>
            <input class="form-colmena" type="text" name="phoneContact" id="phoneContact" :placeholder='$t("simulation.phone.placeholder")'
                   :class="{'is-invalid' : $v.input.phone.$error}"
                   v-model.trim="$v.input.phone.$model"/>
            <span class="invalid-feedback" v-show="$v.input.phone.$error && !$v.input.phone.required">
                                {{$t("simulation.required")}}</span>
            <span class="invalid-feedback" v-show="$v.input.phone.$error && $v.input.phone.required && !$v.input.phone.isPhone">
                                {{$t("simulation.number_invalid_format")}}</span>
          </div>
          <div class="form-group" :class="{ 'has-error': $v.input.observations.$error }">
            <label for="observationsContactModal" class="control-label" style="margin-top:16px">{{$t("simulation.contact.obs")}}</label>
            <textarea class="form-colmena" style="height:100px" type="text" name="observationsContactModal" id="observationsContactModal" placeholder=""
                      :class="{'is-invalid' : $v.input.observations.$error}"
                      v-model.trim="$v.input.observations.$model"/>
            <span class="invalid-feedback" v-show="$v.input.observations.$error && !$v.input.observations.required">
                                {{$t("simulation.required")}}</span>
          </div>
        </div>
        <div class="clearfix" style="text-align: center; margin-top:30px">
          <v-button type="submit" :loading="save_click" :text='$t("simulation.contact_no_qualif.button")'/>
        </div>
        <div class="col-sm-12">
          <div class="justify-content-center" style="text-align: center" >
            <span class="main-link" v-on:click="back">{{$t("simulation.payment.backStep")}}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators';

import vButton from "@/components/VButton";
import {SegTrabalhoStepsEnum} from "@/enums";

const isPhoneValid = (input) => {
  return input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) != null;
}

export default {
  name: 'SimulationStepContact',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        contact_name: null,
        contact_email: null,
        phone: null,
        observations: null,
        step: null,
      },
      save_click: false,
    };
  },
  validations: {
    input: {
      contact_name: {
        required,
      },
      contact_email: {
        required,
        email
      },
      phone: {
        required,
        isPhone(value) {
          if (value === null || value === '') return true;
          return isPhoneValid(value);
        },
      },
      observations: {

      },
    }
  },

  mounted() {
    if (this.simulation !== null) {
      this.input = this.simulation;
      this.input.contact_email = this.simulation.email;
    }
    this.save_click = false;
    window.scrollTo(0,0);
  },
  computed: {
    titleText: {
      // getter
      get: function () {
        if (this.simulation.status === SegTrabalhoStepsEnum.TAXA_NO_CAE)
          return "simulation.contact_no_cae.title";
        else if (this.simulation.status === SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF)
          return "simulation.contact_no_qualif.title";
        else
          return "simulation.nif_individual.title";
      },
    },
    subtitleText: {
      // getter
      get: function () {
        if (this.simulation.status === SegTrabalhoStepsEnum.TAXA_NO_CAE)
          return "simulation.contact_no_cae.description"
        else if (this.simulation.status === SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF)
          return "simulation.contact_no_qualif.description";
        else
          return "simulation.nif_individual.description";
      },
    },
  },
  methods: {
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.contact_name.$error)
            document.getElementById("contact_nameContact").focus();
          else if (this.$v.input.contact_email.$error)
            document.getElementById("contact_emailContact").focus();
          else if (this.$v.input.phone.$error)
            document.getElementById("phoneContact").focus();

          return;
        }
        document.activeElement.blur();
        this.$emit('saveStepContact',
            {
              input: this.input,
            }
        );
      }
    },

    back() {
      this.$emit('backStepContact');
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>
