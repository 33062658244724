<template>
  <tr>
    <td style="width:239px">
      <div v-show = "canEdit === false">
        {{ item.name }}
      </div>
      <input ref="namePaymentItem" id="namePaymentItem" name="namePaymentItem" class="form-colmena-list" style="width: 215px"
             :class="{'is-form-invalid' : $v.item.name.$error}"
             v-show = "canEdit === true"
             v-model = "item.name"
             @keyup.enter = "checkKeyEnter">
    </td>
    <td style="width:200px">
      <div v-show = "canEdit === false">
        {{ item.task }}
      </div>
      <input name="taskPaymentItem" id="taskPaymentItem" class="form-colmena-list" style="width: 176px"
             v-show = "canEdit === true"
             v-model = "item.task"
             @keyup.enter = "checkKeyEnter">
    </td>
    <td style="width:130px">
      <div v-show = "canEdit === false">
        {{ parseFloat(item.salary).toFixed(2).replace('.', ',') }}€
      </div>
      <input name="salaryPaymentItem" id="salaryPaymentItem" class="form-colmena-list" style="width: 106px"
             :class="{'is-form-invalid' : $v.item.salary.$error}"
             v-show = "canEdit === true"
             v-model = "salaryInput"
             @keyup.enter = "checkKeyEnter">
    </td>
    <td style="width:101px; text-align: right">
      <icon-button icon="delete" @action="deleteEmployee(index)" style="margin-right:8px"></icon-button>
      <icon-button icon="edit" @action="editEmployee(index)"  style="margin-right:8px"></icon-button>
    </td>
  </tr>
</template>

<script>

import IconButton from "@/components/IconButton";
import {required} from 'vuelidate/lib/validators';

const isDecimalNumber = (input) => {
  var v = input.replace(',', '.');

  // final check
  return v === (parseFloat(v) + '') ||
      v === (parseFloat(v).toFixed(1) + '') ||
      v === (parseFloat(v).toFixed(2) + '');
}

export default {
  name: 'SimulationStepPaymentItem',

  props: {
    item: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false
    }
  },
  components: {
    IconButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      save_click: false,
      canEdit: false,
      goNext: false,
      set_salary: false,
    };
  },
  validations: {
    item: {
      name: {
        required
      },
      salary: {
        required,
        isDecimal(value) {
          if (value === null || value === '') return true;
          this.item.salary = this.item.salary.replace(',', '.');
          return isDecimalNumber(value);
        },
      },
    }
  },
  computed: {
    salaryInput: {
      // getter
      get: function () {
        if (this.item.salary !== null && this.item.salary !== "" && (this.item.salary + '').indexOf('.') > -1 && !(this.item.salary + '').indexOf('.00') > -1) {
          if (!this.set_salary)
            return (parseFloat(this.item.salary).toFixed(2) + '').replace('.', ',');
          else
            return this.item.salary.replace('.', ',');
        }
        return this.item.salary;
      },
      // setter
      set: function (newValue) {
        this.set_salary = true;
        this.item.salary = newValue.replace(',', '.');
      }
    }
  },
  mounted() {
    if (this.item.idemployee !== null) {
      this.canEdit = false;
      this.goNext = false;
    } else {
      this.canEdit = true;
      this.goNext = true;
      this.focus = true;
    }
    if (this.item.doScroll) {
      this.$emit('doScrollEvent', {});
    }
    this.save_click = false;
  },
  updated() {
    if (this.canEdit && this.focus) {
      this.focus = false;
      this.$refs.namePaymentItem.focus();
    }
  },
  methods: {
    validate(ignoreIfLast) {
      if (!this.canEdit)
        return true;
      if (ignoreIfLast && this.isEmpty()) {
        this.$v.$reset();
        return true;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (this.$v.item.name.$error)
          document.getElementById("namePaymentItem").focus();
        else if (this.$v.item.salary.$error)
          document.getElementById("salaryPaymentItem").focus();
        return false;
      }
      return true;
    },
    isEmpty() {
      return (this.item.name === '' || this.item.name == null) && (this.item.task === '' || this.item.task == null) && (this.item.salary === '' || this.item.salary == null);
    },
    enableButton() {
      this.save_click = false;
    },
    addEmployee() {
      this.$emit('addEvent', {});
    },
    editEmployee(index) {
      this.$emit('closeOthersEvent',
          {
            index: index
          }
      );
    },
    deleteEmployee(index) {
      this.$emit('deleteEvent',
          {
            index: index
          }
      );
    },
    allowEdit() {
      this.canEdit = true;
      this.focus = true;
    },
    closeEdit() {
      this.item.doScroll = false;
      this.canEdit = false;
    },
    checkKeyEnter() {
      if (this.validate()) {
        this.canEdit = false;
        if (this.item.idemployee === null && this.item.doScroll) {
          this.item.doScroll = false;
          this.addEmployee();
        }
      }
    }
  },
};
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
/* Set a fixed scrollable wrapper */
.tableWrap {
  max-height: 600px;
  overflow: auto;
  scrollbar-width: thin;
}
/* Set header to stick to the top of the container. */
thead tr th {
  position: sticky;
  top: 0;
}

/* If we use border,
we must use table-collapse to avoid
a slight movement of the header row */
table {
  border-collapse: collapse;
}

/* Because we must set sticky on th,
 we have to apply background styles here
 rather than on thead */
th {
  background: white;
  text-align: left;
  box-shadow: 1px 0 0 1px #e8e8e8;
}
</style>