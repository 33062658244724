<template>
  <div class="col-sm-11 mt-3">
    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <h5 class="main-step">{{$t("simulation.step", {step:2, total:3})}}</h5>

      <h5 class="main-question-qualif" style="margin-bottom: 16px">{{$t("simulation.data.title")}}</h5>

      <div class="form-group" :class="{ 'has-error': $v.input.company_name.$error }">
        <label for="company_nameData" class="control-label">{{$t("simulation.company_name")}}</label>
        <input class="form-colmena" type="text" name="company_nameData" id="company_nameData" :placeholder='$t("simulation.company_name.placeholder")'
               :class="{'is-invalid' : $v.input.company_name.$error}"
               v-model.trim="$v.input.company_name.$model"/>
        <span class="invalid-feedback" v-show="$v.input.company_name.$error && !$v.input.company_name.required">
                                {{$t("simulation.required")}}</span>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.nif.$error }">
            <label for="nifData" class="control-label">{{$t("simulation.nif")}}</label>
            <input class="form-colmena" type="text" name="nifData" id="nifData" :placeholder='$t("simulation.nif.placeholder")' disabled
                   :class="{'is-invalid' : $v.input.nif.$error}"
                   v-model.trim="$v.input.nif.$model"/>
            <span class="invalid-feedback" v-show="$v.input.nif.$error && !$v.input.nif.required">
                                {{$t("simulation.required")}}</span>
            <span class="invalid-feedback" v-show="$v.input.nif.$error && !$v.input.nif.isValidNIF">
                                {{$t("simulation.number_invalid_format")}}</span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.cae.$error }">
            <label for="caeData" class="control-label">{{$t("simulation.cae")}}</label>
            <input class="form-colmena" type="text" name="caeData" id="caeData" :placeholder='$t("simulation.cae.placeholder")' disabled
                   :class="{'is-invalid' : $v.input.cae.$error}"
                   v-model.trim="$v.input.cae.$model"/>
            <span class="invalid-feedback" v-show="$v.input.cae.$error && !$v.input.cae.required">
                                {{$t("simulation.required")}}</span>
          </div>
        </div>
      </div>

      <div class="form-group" :class="{ 'has-error': $v.input.address.$error }">
        <label for="addressData" class="control-label">{{$t("simulation.address")}}</label>
        <input class="form-colmena" type="text" name="addressData" id="addressData" :placeholder='$t("simulation.address.placeholder")'
               :class="{'is-invalid' : $v.input.address.$error}"
               v-model.trim="$v.input.address.$model"/>
        <span class="invalid-feedback" v-show="$v.input.address.$error && !$v.input.address.required">
                                {{$t("simulation.required")}}</span>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.postal_code.$error }">
            <label for="postal_codeData" class="control-label">{{$t("simulation.postal_code")}}</label>
            <div class="row">
              <div class="col-sm-5">
                <input class="form-colmena" type="text" name="postal_codeData" id="postal_codeData"
                       :class="{'is-invalid' : $v.input.postal_code.$error}"
                       v-model.trim="$v.input.postal_code.$model"/>
              </div>
              <div class="col-sm-4">
                <input class="form-colmena" type="text" name="postal_code2Data" id="postal_code2Data"
                       :class="{'is-invalid' : $v.input.postal_code2.$error}"
                       v-model.trim="$v.input.postal_code2.$model"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                  <span class="invalid-feedback" style="display:block" v-show="($v.input.postal_code.$error && !$v.input.postal_code.required) ||
                                    ($v.input.postal_code2.$error && !$v.input.postal_code2.required)">
                                    {{$t("simulation.required")}}</span>
                <span class="invalid-feedback" style="display:block" v-show="($v.input.postal_code.$error || $v.input.postal_code2.$error) && $v.input.postal_code.required && $v.input.postal_code2.required">
                                    {{$t("simulation.number_invalid_format")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.city.$error }">
            <label for="cityData" class="control-label">{{$t("simulation.city")}}</label>
            <input class="form-colmena" type="text" name="cityData" id="cityData" :placeholder='$t("simulation.city.placeholder")'
                   :class="{'is-invalid' : $v.input.city.$error}"
                   v-model.trim="$v.input.city.$model"/>
            <span class="invalid-feedback" v-show="$v.input.city.$error && !$v.input.city.required">
                                {{$t("simulation.required")}}</span>
          </div>
        </div>
      </div>
      <div class="form-group" :class="{ 'has-error': $v.input.contact_name.$error }">
        <label for="contact_nameData" class="control-label" >{{$t("simulation.contact_name")}}</label>
        <input type="text" class="form-colmena" id="contact_nameData" name="contact_nameData" :placeholder='$t("simulation.contact_name.placeholder")'
               :class="{'is-invalid' : $v.input.contact_name.$error}"
               v-model.trim="$v.input.contact_name.$model">
        <div class="invalid-feedback" v-show="$v.input.contact_name.$error && !$v.input.contact_name.required">{{$t("simulation.required")}}</div>
        <div class="invalid-feedback" v-show="$v.input.contact_name.$error && $v.input.contact_name.required && !$v.input.contact_name.maxLength">{{$t("simulation.maxLength", { max: "255"})}}</div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.contact_email.$error }">
            <label for="contact_emailData" class="control-label" >{{ $t("simulation.email") }}</label>
            <input type="email" class="form-colmena" id="contact_emailData" name="contact_emailData" :placeholder='$t("simulation.email.placeholder")'
                   :class="{'is-invalid' : $v.input.contact_email.$error}"
                   v-model.trim="$v.input.contact_email.$model">
            <div class="invalid-feedback" v-show="$v.input.contact_email.$error && !$v.input.contact_email.required">{{$t("simulation.required")}}</div>
            <div class="invalid-feedback" v-show="$v.input.contact_email.$error && $v.input.contact_email.required && !$v.input.contact_email.email">{{$t("simulation.email_valid")}}</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': $v.input.phone.$error }">
            <label for="phoneData" class="control-label" >{{ $t("simulation.phone") }}</label>
            <input type="text" class="form-colmena" id="phoneData" name="phoneData" :placeholder='$t("simulation.phone.placeholder")'
                   :class="{'is-invalid' : $v.input.phone.$error}"
                   v-model.trim="$v.input.phone.$model">
            <span class="invalid-feedback" v-show="$v.input.phone.$error && !$v.input.phone.isPhone">{{$t("simulation.number_invalid_format")}}</span>
          </div>
        </div>
      </div>

      <div class="form-group" :class="{ 'has-error': $v.input.start_date.$error }">
        <label for="start_dateData" class="control-label">{{$t("simulation.start_date")}}</label>
        <date-picker name="start_dateData" id="start_dateData"  :value.sync="input.start_date"
                     :start-date="start_date"
                     :end-date="end_date"
                     :class="{'is-invalid': $v.input.start_date.$error}"
                     :error-class="{'is-invalid': $v.input.start_date.$error}"></date-picker>
        <span class="invalid-feedback" v-show="$v.input.start_date.$error && !$v.input.start_date.required">
                                {{$t("simulation.required")}}</span>
      </div>
      <div class="row">
        <h5 class="main-description-small">
          <i18n path="simulation.simulation.data.days" tag="label" for="simulation.simulation.data.days1">
            <span v-on:click="stepReminder" class="main-description-small main-link-small">{{ $t('simulation.simulation.data.days1') }}</span>
          </i18n>
        </h5>
      </div>

      <hr>
      <div class="justify-content-center" style="text-align: center; margin-top:32px">
        <h5 class="main-description"> {{$t("simulation.data.best_offer")}}</h5>
        <h5 class="result-name"> Caravela Seguros</h5>
      </div>
      <div class="justify-content-center" style="text-align: center; margin-top:4px">
        <span class="result-value"> {{ (simulation.insurance_value + '').replace('.', ',') }}€</span>
        <span class="result-time"> / ANUAIS</span>
      </div>
      <div class="row justify-content-center">
        <h5 class="col-sm-7 main-description-small">{{ $t('simulation.simulation.data.note') }}</h5>
      </div>

      <div class="clearfix" style="text-align: center; margin-top:18px">
        <v-button type="submit" :loading="save_click" :text='$t("simulation.continue")'/>
      </div>
      <div class="row justify-content-center">
        <h5 class="main-description-small">
          <i18n path="simulation.simulation.docs" tag="label" for="simulation.simulation.docs1">
            <span v-on:click="openFile1" class="main-description-small main-link-small">{{ $t('simulation.simulation.docs1') }}</span>
            <span v-on:click="openFile2" class="main-description-small main-link-small">{{ $t('simulation.simulation.docs2') }}</span>
          </i18n>
        </h5>
      </div>
    </form>
  </div>
</template>


<script>
import {required, integer, maxLength, minLength, email} from 'vuelidate/lib/validators';

import vButton from '../VButton';
import DatePicker from '../DatePicker';
import moment from "moment";
import {sendDataLayerInfo} from "@/utils";
import {SegTrabalhoTagsEnum} from "@/enums";


function validNIF(nif) {
  if (nif.length === 9) {
    if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
        !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2)))
      return false;

    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;

    let modulo11 = total - parseInt(total / 11) * 11;
    let comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;

    return parseInt(nif[8]) === parseInt(comparador);
  } else {
    return false;
  }
}

const isPhoneValid = (input) => {
  return input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) != null;
}

export default {
  name: 'SimulationStepData',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
    DatePicker,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        company_name: null,
        nif: null,
        cae: null,
        premio: null,
        start_date: null,
        city: null,
        address: null,
        postal_code: null,
        postal_code2: null,
        phone: null,
        contact_email: null,
        contact_name: null,
      },
      save_click: false,
      start_date: moment().format('DD / MM / YYYY'),
      end_date: moment().add(3, 'months').format('DD / MM / YYYY'),
    };
  },
  validations: {
    input: {
      company_name: {
        required,
      },
      nif: {
        required,
        isValidNIF(value) {
          if (value === null || value === '') return false;
          return validNIF(value);
        },
      },
      cae: {
        required,
      },
      premio: {
        required
      },
      start_date: {
        required
      },
      city: {
        required
      },
      address: {
        required
      },
      contact_name: {
        required
      },
      contact_email: {
        required,
        email,
        maxLength: maxLength(255),
      },
      phone: {
        isPhone(value) {
          if (value === null || value === '') return true;
          return isPhoneValid(value);
        },
      },
      postal_code: {
        required,
        integer,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      postal_code2: {
        required,
        integer,
        minLength: minLength(3),
        maxLength: maxLength(3)
      },
    }
  },

  mounted() {
    if (this.simulation !== null) {
      this.input.company_name = this.simulation.company_name;
      this.input.nif = this.simulation.nif;
      this.input.cae = this.simulation.cae;
      this.input.premio = this.simulation.premio;
      this.input.city = this.simulation.city;
      this.input.address = this.simulation.address;
      this.input.postal_code = this.simulation.postal_code;
      this.input.postal_code2 = this.simulation.postal_code2;
      this.input.contact_name = this.simulation.contact_name;
      this.input.contact_email = this.simulation.contact_email != null ? this.simulation.contact_email : this.simulation.email;
      this.input.phone = this.simulation.phone;
      this.input.start_date = this.simulation.start_date;
    }
    window.scrollTo(0,0);
    this.save_click = false;
  },
  created() {
    sendDataLayerInfo(SegTrabalhoTagsEnum.DADOS);
  },
  methods: {
    stepReminder() {
      this.$emit('loadStepReminder');
    },
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.company_name.$error)
            document.getElementById("company_nameData").focus();
          else if (this.$v.input.nif.$error)
            document.getElementById("nifData").focus();
          else if (this.$v.input.cae.$error)
            document.getElementById("caeData").focus();
          else if (this.$v.input.address.$error)
            document.getElementById("addressData").focus();
          else if (this.$v.input.postal_code.$error)
            document.getElementById("postal_codeData").focus();
          else if (this.$v.input.postal_code2.$error)
            document.getElementById("postal_code2Data").focus();
          else if (this.$v.input.city.$error)
            document.getElementById("cityData").focus();
          else if (this.$v.input.contact_name.$error)
            document.getElementById("contact_nameData").focus();
          else if (this.$v.input.contact_email.$error)
            document.getElementById("contact_emailData").focus();
          else if (this.$v.input.phone.$error)
            document.getElementById("phoneData").focus();
          else if (this.$v.input.start_date.$error)
            document.getElementById("start_dateData").focus();
          return;
        }
        document.activeElement.blur();
        this.$emit('saveStepData',
            {
              input: this.input,
            }
        );
      }
    },
    enableButton() {
      this.save_click = false;
    },
    openFile1() {
      window.open('/assets/docs/CGerais-AT-Conta-dOutrem-Versao-no-03-julho-2021.pdf', '_black');
    },
    openFile2() {
      window.open('/assets/docs/DISPS_A-T-Conta-doutrém_Versão-para-publicação_vf_2020.pdf', '_black');
    }
  },
};
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
.result-name {
//styleName: H2;
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 0px;
  /* Black */

  color: #110522;
}

.result-value {
//styleName: Big text;
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 68px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 82px;
  letter-spacing: 0.35px;
  text-align: center;
  margin-bottom: 0;
  /* Black */

  color: #110522;
}

.result-time {
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;

  text-transform: uppercase;
  /* Black */

  color: #110522;
}
</style>
