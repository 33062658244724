const SegTrabalhoStepsEnum = Object.freeze({
    NO_DATA: "NO_DATA",
    INICIO: "INICIO",
    INICIO_SALARY_HIGHER: "INICIO_SALARY_HIGHER",
    INICIO_SALARY_HIGHER_EMAIL: "INICIO_SALARY_HIGHER_EMAIL",
    INICIO_NIF_INDIVIDUAL: "INICIO_NIF_INDIVIDUAL",
    INICIO_NIF_INDIVIDUAL_EMAIL: "INICIO_NIF_INDIVIDUAL_EMAIL",
    TAXA_NO_CAE: "TAXA_NO_CAE",
    TAXA_NO_CAE_EMAIL: "TAXA_NO_CAE_EMAIL",
    TAXA: "TAXA",
    PROPOSTA: "PROPOSTA",
    PROPOSTA_REMINDER: "PROPOSTA_REMINDER",
    REMINDER_FINAL: "REMINDER_FINAL",
    COMPRAR_QUALIF: "COMPRAR_QUALIF",
    COMPRAR_QUALIF_REMINDER: "COMPRAR_QUALIF_REMINDER",
    COMPRAR_NO_QUALIF: "COMPRAR_NO_QUALIF",
    COMPRAR_NO_QUALIF_EMAIL: "COMPRAR_NO_QUALIF_EMAIL",
    COMPRAR_DADOS: "COMPRAR_DADOS",
    COMPRAR_DADOS_SALARY_HIGHER: "COMPRAR_DADOS_SALARY_HIGHER",
    COMPRAR_DADOS_SALARY_HIGHER_EMAIL: "COMPRAR_DADOS_SALARY_HIGHER_EMAIL",
    COMPRAR_IMPORT: "COMPRAR_IMPORT",
    COMPRAR_APOLICE: "COMPRAR_APOLICE"
});

export default SegTrabalhoStepsEnum;