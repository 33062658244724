<template>

  <div class="row align-middle justify-content-center margin-auto">
    <div class="container main-panel-over" style="background-color: white">
      <contact-modal ref="contactModal" @saveContact="sendContactForm"></contact-modal>
      <capital-error-modal ref="capitalErrorModal" @sendContact="showContactForm"></capital-error-modal>
      <success-modal ref="successModal"></success-modal>
      <warning-modal ref="warningModal"></warning-modal>
      <current-apolice-modal ref="currentApoliceModal" :simulation.sync="simulacao" @saveFromCurrentApolice="saveStepCompraDadosFromApolice"></current-apolice-modal>
      <div class="row align-middle margin-panel">
        <step-progress :status.sync="simulacao.status" @changeStep="changeStep"></step-progress>
      </div>
      <div class="row align-middle justify-content-center">
        <simulation-step-start v-if="simulacao.status === SegTrabalhoStepsEnum.NO_DATA" :simulation.sync="simulacao" ref="SimulationStepStart" @saveStepStart="saveStepStart"/>
        <simulation-step-tax v-if="simulacao.status === SegTrabalhoStepsEnum.INICIO || simulacao.status === SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER" :simulation.sync="simulacao" ref="SimulationStepTax" @saveStepTax="saveStepTaxa" @saveContact="showContactForm"/>
        <simulation-step-simulation v-if="simulacao.status === SegTrabalhoStepsEnum.TAXA" :simulation.sync="simulacao" ref="SimulationStepSimulation" @saveStepSimulation="saveStepProposta" @loadStepReminder="loadStepPropostaReminder" @saveContact="showContactForm"/>
        <simulation-step-reminder v-if="simulacao.status === SegTrabalhoStepsEnum.PROPOSTA_REMINDER || simulacao.status === SegTrabalhoStepsEnum.COMPRAR_QUALIF_REMINDER" :simulation.sync="simulacao" ref="SimulationStepReminder" @saveStepSimulationReminder="saveStepPropostaReminder" @backStepSimulationReminder="backStepPropostaReminder"/>
        <simulation-step-reminder-final v-if="simulacao.status === SegTrabalhoStepsEnum.REMINDER_FINAL" :simulation.sync="simulacao" ref="SimulationStepReminderFinal"/>
        <simulation-step-qualif v-if="simulacao.status === SegTrabalhoStepsEnum.PROPOSTA" :simulation.sync="simulacao" ref="SimulationStepQualif"  @saveStepQualif="saveStepComprarQualif"/>
        <simulation-step-contact v-if="simulacao.status === SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF || simulacao.status === SegTrabalhoStepsEnum.TAXA_NO_CAE  || simulacao.status === SegTrabalhoStepsEnum.INICIO_NIF_INDIVIDUAL" :simulation.sync="simulacao" ref="SimulationStepContact"  @backStepContact="backStepContact" @saveStepContact="saveStepContact"/>
        <simulation-step-data v-if="simulacao.status === SegTrabalhoStepsEnum.COMPRAR_QUALIF" :simulation.sync="simulacao" ref="SimulationStepData" @saveStepData="saveStepCompraDados" @loadStepReminder="loadStepPropostaReminder"/>
        <simulation-step-import v-if="simulacao.status === SegTrabalhoStepsEnum.COMPRAR_IMPORT && !hide_hapi" :simulation.sync="simulacao" ref="SimulationStepImport" @saveStepImport="saveStepImport"/>
        <simulation-step-payment v-if="simulacao.status === SegTrabalhoStepsEnum.COMPRAR_DADOS || simulacao.status === SegTrabalhoStepsEnum.COMPRAR_DADOS_SALARY_HIGHER
          || (hide_hapi && simulacao.status === SegTrabalhoStepsEnum.COMPRAR_IMPORT)" :simulation.sync="simulacao" ref="SimulationStepPayment" @showCapitalError="showCapitalErrorPayment" @saveStepPayment="saveStepCompraApolice"/>
        <simulation-step-final v-if="simulacao.status === SegTrabalhoStepsEnum.COMPRAR_APOLICE" ref="SimulationStepFinal"/>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import {handleAjaxError, sendDataLayerInfo} from "@/utils";
import {mapActions, mapGetters} from 'vuex';
import {ajaxErrorMixin} from "@/mixins";
import simulationStepStart from '../components/simulation/SimulationStepStart';
import simulationStepImport from '../components/simulation/SimulationStepImport';
import simulationStepTax from '../components/simulation/SimulationStepTax';
import simulationStepData from '../components/simulation/SimulationStepData';
import simulationStepSimulation from '../components/simulation/SimulationStepSimulation';
import simulationStepReminder from '../components/simulation/SimulationStepReminder';
import simulationStepReminderFinal from '../components/simulation/SimulationStepReminderFinal';
import simulationStepQualif from '../components/simulation/SimulationStepQualif';
import simulationStepFinal from '../components/simulation/SimulationStepFinal';
import StepProgress from '../components/StepProgress';
import ContactModal from "../components/modal/ContactModal";
import SimulationStepContact from "@/components/simulation/SimulationStepContact";
import SimulationStepPayment from "@/components/simulation/SimulationStepPayment";
import SuccessModal from "@/components/modal/SuccessModal";
import CurrentApoliceModal from "@/components/modal/CurrentApoliceModal";
import {SegTrabalhoStepsEnum} from "./../enums";
import warningModal from "@/components/modal/WarningModal";
import CapitalErrorModal from "@/components/modal/CapitalErrorModal";
import SegTrabalhoTagsEnum from "../enums/modules/SegTrabalhoTagsEnum";

export default {
  name: "Home",
  components: {
    warningModal,
    CurrentApoliceModal,
    SuccessModal,
    SimulationStepContact,
    simulationStepStart,
    simulationStepImport,
    simulationStepTax,
    simulationStepData,
    simulationStepSimulation,
    SimulationStepPayment,
    simulationStepReminder,
    simulationStepReminderFinal,
    simulationStepQualif,
    simulationStepFinal,
    StepProgress,
    ContactModal,
    CapitalErrorModal
  },
  mixins: [
    ajaxErrorMixin
  ],
  data() {
    return {
      id: this.$route.params.id === undefined ? null : this.$route.params.id,
      hide_hapi: true,
      simulacao: {
        status: SegTrabalhoStepsEnum.NO_DATA,
        email: null,
        company_name: null,
        nif: null,
        cae: null,
        premio: null,
        capital: null,
        total_persons: null,
        contact_name: null,
        phone: null,
        start_date: null,
        insurance_value: null,
        cae_tax: null,
        cae_description: null,
        custom_tax: null,
        idseg_ac_trabalho: null,
        current_apolice_number: null,
        payment: null,
        payment_type: null,
        iban: null,
        contact_email: null,
        renovation_date_alert: null,
        renovation_alert_send: null,
        renovation_alert_email_sent: null,
        no_qualify_email_sent: null,
        observations: null,
        loaded_hapi: null,
        website: null,
        employees: []
      },
      SegTrabalhoStepsEnum,
    }
  },
  mounted() {
    if (this.id !== null) {
      this.loadDataFromToken();
    }
  },
  computed: {
    ...mapGetters({
          language: 'getLanguage',
        },
    ),
  },
  watch: {
    '$route.params': {
      handler() {
        this.id = this.$route.params.id === undefined ? null : this.$route.params.id;
        if (this.id !== null) {
          this.loadDataFromToken();
        } else {
          this.simulacao = {};
          this.simulacao.status = SegTrabalhoStepsEnum.NO_DATA;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setWebsite: 'setWebsite',
    }),
    changeStep(reqData) {
      if (reqData.input === SegTrabalhoStepsEnum.INICIO && this.simulacao.cae_tax === null) {
        const self = this;
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.loadTaxValues(this.simulacao.cae).url;
        // GET request
        axios.get(url).then(function (response) {
          self.simulacao.cae_tax = response.data.cae_tax;
          self.simulacao.cae_description = response.data.cae_description;
          self.simulacao.custom_tax = response.data.custom_tax;
          self.simulacao.status = reqData.input;
        }).catch(function (error) {
          // handle error
          handleAjaxError(error);
        });
      } else {
        this.simulacao.status = reqData.input;
      }
    },
    showCapitalErrorPayment() {
      this.$refs.capitalErrorModal.openModal(this.$t("simulation.tax.capital_error"), this.simulacao);
      this.$refs.SimulationStepPayment.enableButton();
    },
    saveStepStart(reqData) {
      let input = reqData.input;
      this.simulacao.email = input.email;
      this.simulacao.nif = input.nif;

      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.loadSimulationByNif(this.simulacao.email, this.simulacao.nif).url;
      // GET request
      axios.get(url).then(function (response) {
        // set data
        if (response.data.nif != null) {
          self.setWebsite(response.data.website);
          self.$refs.warningModal.openModal(
              self.$t("simulation.loaded.description"),
              self.$t("simulation.continue"),
              function() {
                self.simulacao = response.data;
                self.$refs.warningModal.closeModal()},
              self.$t("simulation.restart"),
              function() {
                self.$refs.warningModal.showLoading();
                self.resetSimulation(reqData)
              },
              function() {
                self.$refs.warningModal.closeModal();
                self.$refs.SimulationStepStart.enableButton();
              });
        } else {
          self.loadFromHApiNIF(reqData);
        }
      }).catch(function (error) {
        // handle error
        handleAjaxError(error);

        self.$refs.SimulationStepStart.enableButton();
      });
    },
    saveStepTaxa(reqData) {
      let input = reqData.input;
      this.simulacao.total_persons = input.total_persons;
      this.simulacao.capital = input.capital;
      if (this.simulacao.cae === null) {
        this.simulacao.cae = input.cae;
      }

      const self = this;
      if (parseFloat(this.simulacao.capital) > 500000) {
        this.$refs.capitalErrorModal.openModal(self.$t("simulation.tax.capital_error"), self.simulacao);
        this.$refs.SimulationStepTax.enableButton();
      } else {
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.saveStepTaxa().url;
        // GET request
        axios.post(url, this.simulacao)
            .then(function (response) {
              //console.log(response);
              self.simulacao = response.data;
              if (self.simulacao.status === SegTrabalhoStepsEnum.TAXA_NO_CAE)
                sendDataLayerInfo(SegTrabalhoTagsEnum.CAE_INEXISTENTE_FORM);

              if (response.data.caravela_error !== null) {
                if (response.data.caravela_error === 'error')
                  self.$refs.successModal.openModal(self.$t("simulation.tax.error"));
                else if (response.data.caravela_error === 'premio') {
                  sendDataLayerInfo(SegTrabalhoTagsEnum.CAE_INEXISTENTE_FORM);
                  self.simulacao.status = SegTrabalhoStepsEnum.TAXA_NO_CAE;
                  // eslint-disable-next-line
                  const url2 = jsRoutes.controllers.Application.saveNewStatusNoQualif().url;
                  // GET request
                  axios.post(url2, self.simulacao)
                      .then(function (/*response*/) {
                        // console.log(response);
                        self.$refs.contactModal.openModal(self.simulacao, self.$t("simulation.tax.price_not_available"));
                      }).catch(function (error) {
                    // handle error
                    handleAjaxError(error);
                  });
                }
                self.$refs.SimulationStepTax.enableButton();
              } else if (response.data.total_persons === null) {
                if (response.data.custom_tax) {
                  self.$refs.SimulationStepTax.saveDataLayer();
                  self.$refs.SimulationStepTax.enableButton();
                }
              }
            })
            .catch(function (/*response*/) {
              //console.log(response);
              // handle error
              self.$refs.SimulationStepTax.enableButton();

              self.$refs.successModal.openModal(self.$t("simulation.tax.error"));
            });
      }
    },
    saveStepProposta() {
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.saveStepProposta().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (response) {
            // console.log(response);
            self.simulacao = response.data;
          }).catch(function (error) {
        // handle error
        handleAjaxError(error);
        self.$refs.SimulationStepSimulation.enableButton();
      });
    },
    loadStepPropostaReminder() {
      if (this.simulacao.status === SegTrabalhoStepsEnum.COMPRAR_QUALIF) {
        sendDataLayerInfo(SegTrabalhoTagsEnum.DADOS_LEMBRETE_FORM);
        this.simulacao.status = SegTrabalhoStepsEnum.COMPRAR_QUALIF_REMINDER;
      } else if (this.simulacao.status === SegTrabalhoStepsEnum.TAXA) {
        sendDataLayerInfo(SegTrabalhoTagsEnum.LEMBRETE_FORM);
        this.simulacao.status = SegTrabalhoStepsEnum.PROPOSTA_REMINDER;
      }
    },
    backStepPropostaReminder() {
      if (this.simulacao.status === SegTrabalhoStepsEnum.COMPRAR_QUALIF_REMINDER)
        this.simulacao.status = SegTrabalhoStepsEnum.COMPRAR_QUALIF;
      else if (this.simulacao.status === SegTrabalhoStepsEnum.PROPOSTA_REMINDER)
        this.simulacao.status = SegTrabalhoStepsEnum.TAXA;
    },
    backStepContact() {
      if (this.simulacao.status === SegTrabalhoStepsEnum.TAXA_NO_CAE)
        this.simulacao.status = SegTrabalhoStepsEnum.NO_DATA;
      else if (this.simulacao.status === SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF)
        this.simulacao.status = SegTrabalhoStepsEnum.PROPOSTA;
      else if (this.simulacao.status === SegTrabalhoStepsEnum.INICIO_NIF_INDIVIDUAL)
        this.simulacao.status = SegTrabalhoStepsEnum.NO_DATA;
    },
    saveStepPropostaReminder(reqData) {
      let input = reqData.input;

      this.simulacao.contact_name = input.contact_name;
      this.simulacao.phone = input.phone;
      this.simulacao.contact_email = input.contact_email;
      this.simulacao.renovation_date_alert = input.renovation_date_alert;
      this.simulacao.language = this.language;

      //console.log("send to server");
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.saveStepPropostaReminder().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (response) {
            if (self.simulacao.status === SegTrabalhoStepsEnum.COMPRAR_QUALIF_REMINDER)
              sendDataLayerInfo(SegTrabalhoTagsEnum.DADOS_LEMBRETE_ENVIADO);
            else if (self.simulacao.status === SegTrabalhoStepsEnum.PROPOSTA_REMINDER)
              sendDataLayerInfo(SegTrabalhoTagsEnum.LEMBRETE_ENVIADO);
            self.simulacao = response.data;
          }).catch(function (error) {
        // handle error
        handleAjaxError(error);
        self.$refs.SimulationStepReminder.enableButton();
      });
    },
    saveStepComprarQualif(reqData) {
      const self = this;
      if (reqData.input === 'true') {

        self.simulacao.status = SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF;
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.saveNewStatusNoQualif().url;
        // GET request
        axios.post(url, self.simulacao)
            .then(function (/*response*/) {
              // console.log(response);
              sendDataLayerInfo(SegTrabalhoTagsEnum.EXCLUSOES_FORM);
            }).catch(function (error) {
          // handle error
          handleAjaxError(error);
          self.$refs.SimulationStepQualif.enableButton();
        });
      } else {
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.saveStepCompraQualif().url;
        // GET request
        axios.post(url, this.simulacao)
            .then(function (response) {
              // console.log(response);
              self.simulacao = response.data;
            }).catch(function (error) {
          // handle error
          handleAjaxError(error);
          self.$refs.SimulationStepQualif.enableButton();
        });
      }
    },
    saveStepContact(reqData) {
      let input = reqData.input;
      this.simulacao.contact_name = input.contact_name;
      this.simulacao.phone = input.phone;
      this.simulacao.contact_email = input.contact_email;
      this.simulacao.observations = input.observations;
      this.simulacao.language = this.language;

      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.saveStepContact().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (/*response*/) {
            // console.log(response);
            if (self.simulacao.status === SegTrabalhoStepsEnum.TAXA_NO_CAE)
              sendDataLayerInfo(SegTrabalhoTagsEnum.CAE_INEXISTENTE_ENVIADO);
            else if (self.simulacao.status === SegTrabalhoStepsEnum.COMPRAR_NO_QUALIF)
              sendDataLayerInfo(SegTrabalhoTagsEnum.EXCLUSOES_ENVIADO);
            else if (self.simulacao.status === SegTrabalhoStepsEnum.INICIO_NIF_INDIVIDUAL)
              sendDataLayerInfo(SegTrabalhoTagsEnum.NIF_INDIVIDUAL_ENVIADO);

            self.$refs.successModal.openModal(self.$t("simulation.message_success"));
            self.simulacao = {
              status: SegTrabalhoStepsEnum.NO_DATA
            };
          }).catch(function (error) {
        // handle error
        handleAjaxError(error);
        self.$refs.SimulationStepContact.enableButton();
      });
    },
    saveStepCompraDados(reqData) {
      let input = reqData.input;
      this.simulacao.premio = input.premio;
      this.simulacao.company_name = input.company_name;
      this.simulacao.nif = input.nif;
      this.simulacao.cae = input.cae;
      this.simulacao.start_date = input.start_date;
      this.simulacao.city = input.city;
      this.simulacao.address = input.address;
      this.simulacao.postal_code = input.postal_code;
      this.simulacao.postal_code2 = input.postal_code2;
      this.simulacao.contact_email = input.contact_email;
      this.simulacao.contact_name = input.contact_name;
      this.simulacao.phone = input.phone;

      if (this.simulacao.custom_tax === 'true') {
        window.scrollTo(0,0);
        this.sendStepCompraDadosToServer('true');
      } else {
        this.$refs.currentApoliceModal.openModal();
      }
    },
    saveStepCompraDadosFromApolice(reqData) {

      let input = reqData.input;
      this.simulacao.current_apolice_number = input.current_apolice_number;
      this.sendStepCompraDadosToServer('false');
    },
    sendStepCompraDadosToServer(direct) {
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.saveStepCompraDados().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (response) {
            // console.log(response);
            window.scrollTo(0,0);
            self.simulacao = response.data;
          })
          .catch(function (response) {
            // handle error
            handleAjaxError(response);
            if (direct === 'true')
              self.$refs.SimulationStepData.enableButton();
          });
    },
    saveStepCompraApolice(reqData) {
      let input = reqData.input;
      this.simulacao.capital = input.capital;
      this.simulacao.total_persons = input.total_persons;
      this.simulacao.payment = input.payment;
      this.simulacao.payment_type = input.payment_type;
      this.simulacao.iban = input.iban;
      this.simulacao.employees = input.employees;
      this.simulacao.language = this.language;
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.saveStepCompraApolice().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (response) {
            if (self.simulacao.premio === 'Fixo')
              sendDataLayerInfo(SegTrabalhoTagsEnum.EMISSAO_APOLICE_FIXO);
            else
              sendDataLayerInfo(SegTrabalhoTagsEnum.EMISSAO_APOLICE_VARIAVEL);
            self.simulacao = response.data;
          })
          .catch(function (response) {
            // handle error
            self.$refs.SimulationStepPayment.enableButton();
            handleAjaxError(response);
          });
    },
    showContactForm() {
      if (this.simulacao.status === SegTrabalhoStepsEnum.INICIO)
        sendDataLayerInfo(SegTrabalhoTagsEnum.OUTROS_PEDIDOS_FORM)
      else if (this.simulacao.status === SegTrabalhoStepsEnum.TAXA)
        sendDataLayerInfo(SegTrabalhoTagsEnum.QUESTOES_ADICIONAIS_FORM);
      this.$refs.contactModal.openModal(this.simulacao, '');
    },
    sendContactForm(reqData) {
      let input = reqData.input;
      this.simulacao.contact_email = input.email;
      this.simulacao.contact_name = input.name;
      this.simulacao.phone = input.phone;
      this.simulacao.observations = input.observations;
      this.simulacao.language = this.language;

      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.sendContactFormEmail().url;
      // GET request
      axios.post(url, this.simulacao)
          .then(function (/*response*/) {
            //console.log(self.simulacao.next_step);
            if (self.simulacao.status === SegTrabalhoStepsEnum.INICIO)
              sendDataLayerInfo(SegTrabalhoTagsEnum.OUTROS_PEDIDOS_ENVIADO);
            else if (self.simulacao.status === SegTrabalhoStepsEnum.TAXA)
              sendDataLayerInfo(SegTrabalhoTagsEnum.QUESTOES_ADICIONAIS_ENVIADO);
            self.$refs.successModal.openModal(self.$t("simulation.message_success"));
            self.$refs.contactModal.closeModal();
          })
          .catch(function (/*response*/) {
            // handle error
            self.$refs.contactModal.closeModal();
            self.$refs.successModal.openModal(self.$t("simulation.message.error"));
          });
    },
    loadFromHApiNIF(reqData) {
      let input = reqData;
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.loadDataFromHApiNIF().url;
      // GET request
      axios.post(url, input).then(function (response) {
        // set data
        //console.log(response);
        self.$refs.warningModal.closeModal()
        self.setWebsite(response.data.website);
        if (self.simulacao.nif === response.data.nif) {
          if (self.simulacao.insurance_value != null) {
            response.data.insurance_value = self.simulacao.insurance_value;
          }
        }
        self.simulacao = response.data;
        if (self.simulacao.status === SegTrabalhoStepsEnum.INICIO_NIF_INDIVIDUAL) {
          sendDataLayerInfo(SegTrabalhoTagsEnum.NIF_INDIVIDUAL_FORM);
        }
      }).catch(function (/*error*/) {
        // handle error
        //handleAjaxError("Não foi possivel carregar os dados da empresa, por favor tente mais tarde", error);
      //  console.log("error:" + error);
        self.$refs.successModal.openModal(self.$t("simulation.hapi.error"));

        self.$refs.SimulationStepStart.enableButton();
        self.$refs.warningModal.closeModal();
      });
    },
    resetSimulation(reqData) {
      let input = reqData;
      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.resetSimulationByNif().url;
      // GET request
      axios.post(url, input).then(function (response) {
        // set data
        // console.log(response);
        self.$refs.warningModal.closeModal()
        self.setWebsite(response.data.website);
        if (self.simulacao.nif === response.data.nif) {
          if (self.simulacao.insurance_value != null) {
            response.data.insurance_value = self.simulacao.insurance_value;
          }
        }
        self.simulacao = response.data;
      }).catch(function (error) {
        // handle error
        handleAjaxError("Não foi possivel carregar os dados da empresa, por favor tente mais tarde", error);
        // console.log("error:" + error);
        self.$refs.SimulationStepStart.enableButton();
        self.$refs.warningModal.closeModal();
      });
    },
    loadFromHApi(reqData) {
      let input = reqData;

      const self = this;
      // eslint-disable-next-line
      const url = jsRoutes.controllers.Application.loadDataFromHApi().url;
      // GET request
      axios.post(url, input).then(function (response) {
        // set data
        self.simulacao = response.data;
      }).catch(function (/*error*/) {
        // console.log(error);
        // handle error
        //handleAjaxError("Não foi possivel carregar os dados da empresa, por favor tente mais tarde", error);

        self.$refs.successModal.openModal(self.$t("simulation.hapi.error"));
        self.$refs.SimulationStepImport.enableButton();
      });
    },


    saveStepImport(reqData) {
      if (reqData.auto) {
        this.loadFromHApi(reqData);
      } else {
        this.simulacao.status = SegTrabalhoStepsEnum.COMPRAR_DADOS;
      }
    },
  }
};
</script>
