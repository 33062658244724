<template>
    <span class="mytext-button" :class="[{'right-arrow' : addArrow}, {'padding-right' : loading}]" v-on:click="clicked">
      <slot v-if="!loading">{{text}}</slot>
      <span v-else><i class="fa fa-spinner fa-spin"></i> {{text}}</span>
    </span>
</template>

<script>
    export default {
        name: "VButton",
        props: {
            loading: Boolean,
            addArrow: Boolean,
            text: {
                type: String,
                default: ""
            }
        },
        methods: {
          clicked() {
            this.$emit('validate', {});
          }
        }
    }
</script>

<style scoped>
.padding-right {
  padding-right:25px;
  cursor: default !important;
}

.mytext-button {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #110522;
  cursor: pointer;

  margin-bottom:24px;
  border-bottom: 3px solid #FADB0F;
}
.mytext-button:hover {
  border-bottom: 3px solid #110522;
}

button:focus,
button:hover,
.mytext-button:focus,
.mytext-button:hover {
  outline: none;
}

.right-arrow:after {
  content:'\279C'
}
</style>
