export default {
    "simulation.404": "Página não encontrada",
    "simulation.loaded.description": "Queres continuar a simulação que ficou pendente?",
    "simulation.step1": "Início",
    "simulation.step2": "Taxa",
    "simulation.step3": "Comprar",
    "simulation.step4": "Confirmação",
    "simulation.email": "Email",
    "simulation.email.placeholder": "Qual é o melhor email para te contactar?",
    "simulation.phone": "Telefone",
    "simulation.hapi.error": "Um erro impede-nos de obter os dados da empresa neste momento. Podes, por favor, tentar novamente mais tarde?",
    "simulation.phone.placeholder" : "Qual é o melhor número de telefone para te contactar?",
    "simulation.start.title": "Ajuda-nos a chegar à melhor proposta para ti",
    "simulation.start.description" : "Não te preocupes. Nós também odiamos spam. Pedimos-te o email para enviar a cotação e prestar os nossos serviços, mas quando quiseres podes responder a qualquer dos nossos emails a pedir e removemos-te da base de dados.",
    "simulation.start.description2" : "Pedimos-te o NIF da empresa para te poupar trabalho e obter automaticamente as informações de que precisamos para te apresentar a melhor proposta.",
    "simulation.start.terms": "Ao clicar em \"Obter preço!\" estás a aceitar a nossa",
    "simulation.start.terms2": "Politica de Privacidade",
    "simulation.get_price": "Ver taxa",
    "simulation.qualify.title": "Alguma das afirmações abaixo é verdadeira?",
    "simulation.qualify.question1": "A empresa tem colaboradores a desempenhar funções no estrangeiro",
    "simulation.qualify.question2": "A empresa trabalha com explosivos",
    "simulation.qualify.question3": "A empresa tem colaboradores a exercer funções a tempo parcial",
    "simulation.qualify.question4": "Há colaboradores que têm como transporte habitual veículos de duas rodas",
    "simulation.qualify.question5": "Há colaboradores com remuneração total anual superior a 70.000€",
    "simulation.next": "Próximo",
    "simulation.name": "Nome",
    "simulation.cellphone": "Telemóvel",
    "simulation.import.title": "Para a emissão da apólice com prémio fixo, precisamos do nome, função e remuneração total anual de todos os colaboradores da empresa.",
    "simulation.import.description": "Para obtermos a informação necessária automaticamente, faz login na Segurança Social Direta:",
    "simulation.data.best_offer" : "Seguro de Acidentes de Trabalho",
    "simulation.data.title": "Confirma, por favor, os dados abaixo, essenciais para a emissão da apólice:",
    "simulation.contact_no_qualif.title": "Entraremos em contacto! ",
    "simulation.contact_no_qualif.description": "Dado que a empresa se encontra num destes casos, não podemos fazer a simulação automaticamente. Autorizas-nos a contactar-te para confirmar alguns dados e encontrar o melhor preço para ti?",
    "simulation.contact_no_cae.title": "Não é possível obter preço para o teu sector no simulador…",
    "simulation.contact_no_cae.description": "No entanto, se nos deixares o teu contacto e o que procuras, nós procuramos por ti e contactamos-te com a oferta mais adequada para ti muito em breve!",
    "simulation.nif_individual.title": "Não é possível obter preço no simulador…",
    "simulation.nif_individual.description": "Por agora, só podemos dar preço no simulador para empresas. No entanto, se nos deixares o teu contacto e o que procuras, nós procuramos por ti e contactamos-te com a oferta mais adequada para ti muito em breve!",
    "simulation.contact_no_qualif.button": "Contactem-me",
    "simulation.payment.variavel.title": "Para emissão da apólice de acidentes de trabalho com prémio variável, precisamos que confirmes a informação abaixo e que definas as preferências de pagamento:",
    "simulation.payment.validation": "Confirmo a validade da informação introduzida e solicito a subscrição da apólice Caravela Acidentes de Trabalho",
    "simulation.payment.fixo.title": "Colaboradores a Segurar",
    "simulation.payment.fixo.description.loaded": "Por favor, confirma, completa ou corrige os dados obtidos da Segurança Social, detalhados na tabela abaixo. O total deve coincidir com o que nos indicaste na simulação, mas, se diferir, vais poder voltar a obter preço antes de emitir a apólice.",
    "simulation.payment.fixo.description": "Para a emissão da apólice com prémio fixo, precisamos que preenchas na tabela abaixo o nome, função e remuneração total anual de todos os colaboradores da empresa. O total deve coincidir com o que nos indicaste na simulação, mas, se diferir, vais poder voltar a obter preço antes de emitir a apólice.",
    "simulation.company_name": "Nome da Empresa",
    "simulation.company_name.placeholder": "Qual é o nome da empresa?",
    "simulation.nif": "NIF da Empresa",
    "simulation.nif.placeholder": "Qual é o NIF da empresa?",
    "simulation.cae": "CAE",
    "simulation.cae.placeholder": "Qual é o código de actividade económica da empresa?",
    "simulation.iban": "IBAN",
    "simulation.capital": "Capital a segurar",
    "simulation.capital.placeholder": "Valor da massa salarial anual",
    "simulation.total_persons": "Número de trabalhadores",
    "simulation.total_persons.placeholder": "Total de trabalhadores a segurar",
    "simulation.tax.title": "Encontrámos uma oferta para o teu sector!",
    "simulation.tax.title_no_cae": "Seguro de Acidentes de Trabalho",
    "simulation.tax.description_no_cae": "A partir de:",
    "simulation.tax.description": "Esta taxa é promocional e válida apenas para empresas como a tua, com um CAE do sector de {sector}!",
    "simulation.tax.description2": "À taxa comercial acrescem impostos e taxas parafiscais, mas não te preocupes que no passo a seguir vamos-te indicar o valor já com tudo incluído.",
    "simulation.tax.question": "Para calcularmos o preço exacto do seguro, só precisamos de mais 2 informações:",
    "simulation.cae.question": "Não foi possível identificar o Código de Actividade Económica da tua empresa, por isso a tua taxa pode ser diferente da que aqui mostramos. Para obtermos o melhor preço possível para a tua empresa e já tendo em conta o teu sector de actividade, indica-nos por favor o teu CAE:",
    "simulation.tax.other": "Se procuras seguros que ainda não estão disponíveis no simulador, deixa-nos {0} o teu contacto e diz-nos o que procuras que nós procuramos por ti. Prometemos ser breves a apresentar-te solução e a disponibilizar novos simuladores!",
    "simulation.tax.other.click": "aqui",
    "simulation.tax.error": "Problemas técnicos impedem-nos de te apresentar um preço neste momento. Podes, por favor, tentar novamente mais tarde? Os dados que já inseriste ficam aqui à espera que regresses! Obrigado.",
    "simulation.tax.price_not_available": "Não é possível apresentar um preço no simulador para o teu caso específico, mas, se nos deixares os teus dados, contactamos-te muito brevemente para te ajudar a encontrar a melhor solução.",
    "simulation.tax.price_not_available_recalc": "Neste momento, não é possível apresentar um preço no simulador para o teu caso específico.",
    "simulation.tax.capital_error": "Nesta campanha só podemos apresentar preços no simulador para capitais seguros até 500.000€. No entanto, se nos deixares o teu contacto e o que procuras, começamos já a trabalhar para te apresentar a melhor proposta para o teu caso específico!",
    "simulation.view_price": "Ver preço",
    "simulation.simulation.title": "Proposta válida 24h!",
    "simulation.simulation.subtitle": "O seguro que te propomos é o Seguro Caravela Acidentes de Trabalho, que te custará apenas:",
    "simulation.simulation.question": "Este é um preço muito especial, no âmbito de uma campanha exclusiva para empresas do teu sector e não o conseguimos garantir indefinidamente, dado que as condições podem mudar a qualquer momento.",
    "simulation.simulation.description": "Sabias que, mesmo que tenhas uma apólice em vigor, podes fazer já a subscrição e definir como data de início qualquer data dos próximos 3 meses? Age já! Não percas este preço…",
    "simulation.simulation.description2": "Se faltam mais de 3 meses para a renovação, ou se ainda assim preferes recuperar o tema noutra altura, podemos-te avisar para fazer nova simulação 45 dias antes da data que nos indicares.",
    "simulation.simulation.reminder": "Recordem-me antes da renovação",
    "simulation.simulation.doubt": "Se tens alguma questão que queiras esclarecer antes, {0} e entramos em contacto contigo.",
    "simulation.simulation.click": "clica aqui",
    "simulation.simulation.por_ano": "Por ano",
    "simulation.simulation.docs": "Consulta aqui as {0} e o {1}.",
    "simulation.simulation.docs1": "Condições Gerais e Especiais.",
    "simulation.simulation.docs2": "Documento de Informação sobre o Produto de Seguros.",
    "simulation.simulation.data.days": "Podes escolher qualquer data dos próximos 3 meses. Para mais de 3 meses, clica {0} e recordamos-te 45 dias antes para obteres novo preço.",
    "simulation.simulation.data.days1": "aqui",
    "simulation.simulation.data.note": "Acresce, apenas no primeiro ano, o custo de emissão da apólice no valor de 5,50€.",
    "simulation.reminder.title": "Muito Bem!",
    "simulation.reminder.description": "Assim faremos. Deixa-nos os teus dados e avisamos-te 45 dias antes da renovação:",
    "simulation.reminder.renovation_date": "Data de renovação do seguro",
    "simulation.reminder.button": "Recordem-me",
    "simulation.contact.phone.placeholder": "Qual é o telefone de contacto?",
    "simulation.contact.obs": "Questões",
    "simulation.address": "Morada da empresa",
    "simulation.address.placeholder": "Qual é a morada da empresa?",
    "simulation.contact_name": "Nome da pessoa de contacto",
    "simulation.contact_name.placeholder":"Qual é o nome da pessoa de contacto?",
    "simulation.city": "Cidade",
    "simulation.city.placeholder": "Cidade",
    "simulation.postal_code": "Código Postal",
    "simulation.payment.message1": "Com que periodicidade preferes fazer os pagamentos?",
    "simulation.payment.message2": "Nota que existe um valor mínimo por recibo de 30€, pelo que podes não ter todas as opções disponíveis.",
    "simulation.payment_method": "Como queres fazer os pagamentos?",
    "simulation.anual": "Anual",
    "simulation.mensal": "Mensal",
    "simulation.trimestral": "Trimestral",
    "simulation.semestral": "Semestral",
    "simulation.email_valid": "Insere, por favor, um endereço de email válido.",
    "simulation.required": "Este campo é obrigatório.",
    "simulation.integer": "Este campo tem de ser um número inteiro.",
    "simulation.maxLength": "O comprimento máximo do campo é {max} caracteres.",
    "simulation.number_invalid_format": "Insere, por favor, um número válido.",
    "simulation.invalid.payment": "Tipo de pagamento escolhido não é permitido para este valor de seguro",
    "simulation.restart": "Recomeçar",
    "simulation.continue": "Continuar",
    "simulation.confirm": "Confirmar",
    "simulation.send" : "Enviar",
    "simulation.contact_me": "Pedir contacto",
    "simulation.recalc" : "Simular com os novos valores",
    "simulation.back_website": "Voltar a indie.pt",
    "simulation.enter": "Entrar",
    "simulation.anual_salary": "Remuneração anual",
    "simulation.task": "Função",
    "simulation.task.placeholder": "Função desempenhada?",
    "simulation.name.placeholder": "Qual é o nome do trabalhador?",
    "simulation.save": "Guardar",
    "simulation.close": "Fechar",
    "simulation.message_success": "Mensagem enviada com sucesso",
    "simulation.message.error": "Não foi possível enviar o pedido de contacto. Podes, por favor, tentar novamente mais tarde?",
    "simulation.current_apolice_number.no_number": "Não tenho",
    "simulation.current_apolice_number": "Número da apólice atualmente em vigor",
    "simulation.current_apolice_number.placeholder": "Qual é o número da apólice actual?",
    "simulation.emit_apolice": "Emitir apólice",
    "simulation.buy_insurance": "Comprar seguro",
    "simulation.premio" : "Prémio",
    "simulation.start_date": "Data de inicio da apólice",
    "simulation.start_date.placeholder": "Escolhe uma data",
    "simulation.username": "Nome de utilizador",
    "simulation.final.title": "Obrigado!",
    "simulation.final.description": "Muito obrigado. Já temos tudo o que precisamos. Vamos preparar toda a documentação e enviar a apólice no prazo máximo de 2 dias úteis. Deves estar também a receber no email que nos indicaste um resumo de toda a informação que nos deste e das condições do seguro contratado. Até breve!",
    "simulation.reminder.final.title": "Obrigado!",
    "simulation.reminder.final.description": "Dia {day} de {month} enviamos-te um email para te lembrar de fazer nova simulação para encontrares os melhores seguros ao melhor preço!",
    "simulation.step": "Passo {step}/{total}",
    "simulation.ssd.password": "Palavra-chave",
    "simulation.ssd.password.placeholder": "Insira a palavra chave",
    "simulation.ssd.username": "Utilizador da Segurança Social",
    "simulation.ssd.username.placeholder": "Insira o NISS",
    "simulation.payment.confirmation": "Precisamos que nos indiques os dados de {persons} colaboradores e a soma dos ordenados anuais deve ser igual a {capital}.",
    "simulation.payment.backStep" : "Voltar",
    "error.general_title": "Erro",
    "error.general_cancel": "Cancelar",
    "error.general_delete": "Apagar",
    "success.general_title": "Confirmado",
    "simulation.payment.type.transf": "Transferência Bancária",
    "simulation.payment.type.service": "Pagamento de Serviços",
    "simulation.payment.type.debito": "Débito Direto",
    "simulation.period.anual": "Anual",
    "simulation.period.semestral": "Semestral",
    "simulation.period.trimestral": "Trimestral",
    "simulation.period.mensal": "Mensal",
}