<template>
  <div class="modal fade"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div v-if="text !== ''" class="col-sm-12 justify-content-center" style="padding: 0px 40px;">
          <h5 class="main-description">{{ text }}</h5>
        </div>
        <form v-on:submit.prevent="validate" id="joinForm" novalidate>
          <div class="modal-body custom-p sm" style="padding: 0 40px">
            <div class="form-group" :class="{ 'has-error': $v.input.email.$error }">
              <label for="emailContactModal" class="control-label" style="margin-top: 0">{{$t("simulation.email")}}</label>
              <input class="form-colmena" type="email" name="emailContactModal" id="emailContactModal" placeholder=""
                     :class="{'is-invalid' : $v.input.email.$error}"
                     v-model.trim="$v.input.email.$model"/>
              <span class="invalid-feedback" v-show="$v.input.email.$error && !$v.input.email.required">
                                {{$t("simulation.required")}}</span>
              <span class="invalid-feedback" v-show="$v.input.email.$error && !$v.input.email.email">
                                {{$t("simulation.email_valid")}}</span>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.input.name.$error }">
              <label for="nameContactModal" class="control-label" style="margin-top:16px">{{$t("simulation.contact_name")}}</label>
              <input class="form-colmena" type="text" name="nameContactModal" id="nameContactModal"  :placeholder='$t("simulation.contact_name.placeholder")'
                     :class="{'is-invalid' : $v.input.name.$error}"
                     v-model.trim="$v.input.name.$model"/>
              <span class="invalid-feedback" v-show="$v.input.name.$error && !$v.input.name.required">
                                {{$t("simulation.required")}}</span>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.input.phone.$error }">
              <label for="phoneContactModal" class="control-label" style="margin-top:16px">{{$t("simulation.phone")}}</label>
              <input class="form-colmena" type="text" name="phoneContactModal" id="phoneContactModal" :placeholder='$t("simulation.contact.phone.placeholder")'
                     :class="{'is-invalid' : $v.input.phone.$error}"
                     v-model.trim="$v.input.phone.$model"/>
              <span class="invalid-feedback" v-show="$v.input.phone.$error && !$v.input.phone.required">
                                {{$t("simulation.required")}}</span>
              <span class="invalid-feedback" v-show="$v.input.phone.$error && $v.input.phone.required && !$v.input.phone.isPhone">
                                {{$t("simulation.number_invalid_format")}}</span>
            </div>
            <div class="form-group" :class="{ 'has-error': $v.input.observations.$error }">
              <label for="observationsContactModal" class="control-label" style="margin-top:16px">{{$t("simulation.contact.obs")}}</label>
              <textarea class="form-colmena" style="height:100px" type="text" name="observationsContactModal" id="observationsContactModal" placeholder=""
                        :class="{'is-invalid' : $v.input.observations.$error}"
                        v-model.trim="$v.input.observations.$model"/>
              <span class="invalid-feedback" v-show="$v.input.observations.$error && !$v.input.observations.required">
                                {{$t("simulation.required")}}</span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <v-button type="submit" :loading="save_click" :text='$t("simulation.send")'/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import {required, email} from "vuelidate/lib/validators";
import vButton from '../VButton';

const isPhoneValid = (input) => {
  return input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) != null;
}

export default {
  name: 'ContactModal',

  components: {
    vButton
  },
  data() {
    return {
      input: {
        email: null,
        name: null,
        phone: null,
        observations: null,
      },
      text: '',
      save_click: false,
    }
  },

  validations: {
    input: {
      email: {
        required,
        email
      },
      name: {
        required
      },
      phone: {
        required,
        isPhone(value) {
          if (value === null || value === '') return true;
          return isPhoneValid(value);
        },
      },
      observations: {

      },
    }
  },

  methods: {
    openModal(data, text) {
      this.text = text;
      this.save_click = false;
      if (data != null) {
        this.input.email = data.email;
      }
      $(this.$refs.warningModalDIV).modal('show');
    },
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.name.$error)
            document.getElementById("nameContactModal").focus();
          else if (this.$v.input.email.$error)
            document.getElementById("emailContactModal").focus();
          else if (this.$v.input.phone.$error)
            document.getElementById("phoneContactModal").focus();
          else if (this.$v.input.observations.$error)
            document.getElementById("observationsContactModal").focus();

          return;
        }
        document.activeElement.blur();

        this.$emit('saveContact',
            {
              input: this.input,
            }
        );
      }
    },
    closeModal() {
      $(this.$refs.warningModalDIV).modal('hide');
      this.save_click = false;
      this.input.name = null;
      this.input.phone = null;
      this.input.observations = null;
      this.$v.$reset();
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-header,
.modal-footer {
  border:0px;
}

</style>
