<template>
  <div class="modal fade"
       tabindex="-1"
       data-keyboard="false" data-backdrop="static"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" @click="closeCallback" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-p sm">
          <h5 class="main-description">{{ text }}</h5>
        </div>
        <div class="modal-footer align-right">
          <v-button type="submit" :small="true" :loading="save_click" @click="cancelCallback" :text="cancel"/>
          <v-button type="submit" :small="true" :loading="save_click" @click="okCallback" :text="ok"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import vButton from '../VButton';

export default {
  name: 'WarningModal',
  components: {
    vButton
  },
  data() {
    return {
      text: null,
      ok: null,
      cancel: null,
      save_click: false,
      okCallback: function () {},
      cancelCallback: function () {},
      closeCallback: function () {},
    }
  },

  methods: {
    openModal(data, buttonOk, buttonOkCallback, buttonCancel, buttonCancelCallback, buttonCloseCallback) {
      this.text = data;
      this.ok = buttonOk;
      this.cancel = buttonCancel;
      this.okCallback = buttonOkCallback;
      this.cancelCallback = buttonCancelCallback;
      this.closeCallback = buttonCloseCallback;
      this.save_click = false;
      $(this.$refs.warningModalDIV).modal('show');
    },
    closeModal() {
      $(this.$refs.warningModalDIV).modal('hide');
    },
    showLoading() {
      this.save_click = true;
    }
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-header,
.modal-footer {
  border:0px;
}

</style>
