<template>
  <div class="col-sm-12 mt-3">
    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <div class="row justify-content-center">
        <div class="col-sm-9">
          <h5 class="main-title">{{simulation.custom_tax ? $t("simulation.tax.title") : $t("simulation.tax.title_no_cae")}}</h5>

          <h5 class="main-description"> {{simulation.custom_tax ? $t("simulation.tax.description", {sector: simulation.cae_description}) : $t("simulation.tax.description_no_cae")}}</h5>
          <h5 class="result-value"> {{ parseFloat(simulation.cae_tax).toFixed((simulation.cae_tax * 100) % 1 > 0 ? 3 : 2).replace('.', ',') }} %</h5>
          <h5 class="main-description" style="margin: 0px 60px 20px;"> {{$t("simulation.tax.description2", { anual: (parseFloat(anual_calc).toFixed(anual_calc % 1 > 0 ? 2 : 0).replace('.', ',')) })}}</h5>
        </div>
        <div class="col-sm-12" v-if="simulation.cae === null">
          <hr>
          <h5 class="main-question"> {{$t("simulation.cae.question")}}</h5>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" :class="{ 'has-error': $v.input.cae.$error }">
                <label for="caeTax" class="control-label">{{$t("simulation.cae")}}</label>
                <input class="form-colmena" type="text" name="caeTax" id="caeTax" :placeholder='$t("simulation.cae.placeholder")'
                       :class="{'is-invalid' : $v.input.cae.$error}"
                       v-model.trim="$v.input.cae.$model"/>
                <span class="invalid-feedback" v-show="$v.input.cae.$error && !$v.input.cae.required">
                                {{$t("simulation.required")}}</span>
                <!--                        <span class="invalid-feedback" v-show="$v.input.cae.$error && !$v.input.cae.integer">
                                                {{$t("simulation.integer")}}</span>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12" v-else>
          <hr>
          <h5 class="main-question"> {{$t("simulation.tax.question")}}</h5>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group" :class="{ 'has-error': $v.input.total_persons.$error }">
                <label for="total_personsTax" class="control-label">{{$t("simulation.total_persons")}}</label>
                <input class="form-colmena" type="text" name="total_personsTax" id="total_personsTax" :placeholder='$t("simulation.total_persons.placeholder")'
                       :class="{'is-invalid' : $v.input.total_persons.$error}"
                       v-model.trim="$v.input.total_persons.$model"/>
                <span class="invalid-feedback" v-show="$v.input.total_persons.$error && !$v.input.total_persons.required">
                                {{$t("simulation.required")}}</span>
                <span class="invalid-feedback" v-show="$v.input.total_persons.$error && !$v.input.total_persons.integer">
                                {{$t("simulation.integer")}}</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group" :class="{ 'has-error': $v.input.capital.$error }">
                <label for="capitalTax" class="control-label">{{$t("simulation.capital")}}</label>
                <input class="form-colmena" type="text" name="capitalTax" id="capitalTax" :placeholder='$t("simulation.capital.placeholder")'
                       :class="{'is-invalid' : $v.input.capital.$error}"
                       v-model.trim="capitalInput"/>
                <span class="invalid-feedback" v-show="$v.input.capital.$error && !$v.input.capital.required">
                                {{$t("simulation.required")}}</span>
                <span class="invalid-feedback" v-show="$v.input.capital.$error && !$v.input.capital.isDecimal">
                                {{$t("simulation.number_invalid_format")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix" style="text-align: center; margin-top:16px">
          <v-button type="submit" :loading="save_click" :text='$t(simulation.cae === null ? "simulation.continue" : "simulation.view_price")'/>
        </div>

        <div v-if="simulation.cae !== null" class="row justify-content-center">
          <h5 class="main-description-small">
            <i18n path="simulation.tax.other" tag="label" for="simulation.tax.other.click">
              <span v-on:click="stepDoubt" class="main-description-small main-link-small">{{ $t('simulation.tax.other.click') }}</span>
            </i18n>
          </h5>
        </div>
      </div>
    </form>
  </div>
</template>


<script>

import vButton from '../VButton';
import {sendDataLayerInfo} from "@/utils";
import {integer, required, requiredIf} from "vuelidate/lib/validators";
import {SegTrabalhoTagsEnum} from "@/enums";

const isDecimalNumber = (input) => {
  var v = (input + '').replace(',', '.');

  // final check
  return v === (parseFloat(v) + '') ||
      v === (parseFloat(v).toFixed(1) + '') ||
      v === (parseFloat(v).toFixed(2) + '');
}

export default {
  name: 'saveStepTax',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    vButton,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      input: {
        cae: null,
        cae_tax: null,
        custom_tax: null,
        total_persons: null,
        capital: null,
        cae_description: null
      },
      set_capital: false,
      save_click: false,
    };
  },
  validations: {
    input: {
      cae: {
        required
      },
      total_persons: {
        required: requiredIf(function() {
          return this.simulation.cae !== null;
        }),
        integer
      },
      capital: {
        required: requiredIf(function() {
          return this.simulation.cae !== null;
        }),
        isDecimal(value) {
          if (value === null || value === '') return true;
          this.input.capital = (this.input.capital + '').replace(',', '.');
          return isDecimalNumber(value);
        },
      },
    }
  },

  computed: {
    capitalInput: {
      // getter
      get: function() {
        if (this.input.capital !== null && this.input.capital !== "" && (this.input.capital + '').indexOf('.') > -1 && !(this.input.capital + '').indexOf('.00') > -1) {
          if (!this.set_capital)
            return (parseFloat(this.input.capital).toFixed(2) + '').replace('.', ',');
          else
            return this.input.capital.replace('.', ',');
        }
        return this.input.capital;
      },
      // setter
      set: function(newValue) {
        this.set_capital = true;
        this.input.capital = newValue.replace(',', '.');
      }
    },
    anual_calc: {
      get: function() {
        return  10000 * (this.simulation.cae_tax / 100);
      }
    }
  },
  mounted() {
    if (this.simulation !== null) {
      this.input.total_persons = this.simulation.total_persons;
      this.input.capital = this.simulation.capital;
      this.input.cae = this.simulation.cae;
      this.input.cae_tax = this.simulation.cae_tax;
      this.input.cae_description = this.simulation.cae_description;
      this.input.custom_tax = this.simulation.custom_tax;
    }
    this.set_capital = false;
    window.scrollTo(0,0);
  },
  created() {
    this.saveDataLayer();
  },
  methods: {
    saveDataLayer() {
      if (this.simulation.cae !== null) {
        sendDataLayerInfo(SegTrabalhoTagsEnum.TAXA);
      }
    },
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.cae.$error)
            document.getElementById("caeTax").focus();
          else if (this.$v.input.total_persons.$error)
            document.getElementById("total_personsTax").focus();
          else if (this.$v.input.capital.$error)
            document.getElementById("capitalTax").focus();
          return;
        }
        this.$v.$reset();
        document.activeElement.blur();
        this.$emit('saveStepTax',
            {
              input: this.input,
            }
        );
      }
    },
    enableButton() {
      this.save_click = false;
      this.$v.$reset();
    },
    stepDoubt() {
      this.$emit('saveContact');
    },

  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.result-value {
//styleName: Big text;
  font-family: Gilroy, Gilroy ExtraBold;
  font-style: normal;
  font-weight: 800 !important;
  font-size: 68px;
  line-height: 82px;
  /* identical to box height, or 76px */

  text-align: center;
  letter-spacing: 0.35px;

  /* Black */

  color: #110522;
}
</style>
