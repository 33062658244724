<template>
    <div class="outerradio">
      <button v-for="o in options" type="button" :class="[{ 'radioselected': selected === o.value }, { 'radiounselected': selected !== o.value }]" :style="minwidth"
              :key="o.value" v-on:click="setRadioOption(o.value)">{{ o.text }}</button>
    </div>
</template>
<script>

export default {
  props: {
    minwidth: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
    }
  },

  methods: {
    setRadioOption(value) {
      this.$emit('setRadioOption',
          {
            input: value,
          }
      );
    },
  },

}
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
.outerradio {
  background: #F5F5F5;
  border-radius: 8px;
  display: inline-block;
  padding: 8px 12px;
}

.radioselected,
.radiounselected {
  min-width: 143px;
  min-height: 45px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
}
.radioselected {
  background: #FFFFFF;
  color: #110522;
  border: 1px solid #FBDE23;
}
.radiounselected{
  background: transparent;
  color: #676767;
  border: 1px solid #f5f5f5;
  opacity: 0.7;
}

.radioselected:focus,
.radiounselected:focus {
  outline:none;
}
</style>
