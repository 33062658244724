<template>
  <div class="modal fade"
       tabindex="-1"
       data-keyboard="false" data-backdrop="static"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="stepBack" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-p sm">
          <h5 class="main-description">{{ text }}</h5>
        </div>

        <div v-show="error_message" class="modal-body custom-p sm">
          <h5 class="main-description">{{ error_message }}</h5>
        </div>
        <div v-if="!error_message && insurance_value">
          <div class="justify-content-center" style="text-align: center; margin-top:32px">
            <h5 class="main-description"> {{$t("simulation.data.best_offer")}}</h5>
            <h5 class="result-name"> Caravela Seguros</h5>
          </div>
          <div class="justify-content-center" style="text-align: center; margin-top:4px">
            <span class="result-value"> {{ (insurance_value.toFixed(insurance_value % 1 !== 0 ? 2 : 0) + '').replace('.', ',') }}€</span>
            <span class="result-time"> / ANUAIS</span>
          </div>
          <div class="row justify-content-center">
            <h5 class="col-sm-7 main-description-small">{{ $t('simulation.simulation.data.note') }}</h5>
          </div>

          <div class="clearfix" style="text-align: center; margin-top:18px">
            <v-button v-on:click="stepSave"  :loading="save_click" :text='$t("simulation.confirm")'/>
          </div>
        </div>
        <div v-if="!insurance_value" class="modal-footer justify-content-center">
          <v-button :loading="save_click" v-on:click="stepRecalc" :text='$t("simulation.recalc")'/>
        </div>
        <div class="justify-content-center" style="text-align: center; margin-bottom: 45px" >
          <span class="main-link" v-on:click="stepBack">{{$t("simulation.payment.backStep")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import vButton from '../VButton';
import axios from "axios";
import {sendDataLayerInfo} from "@/utils";
import {SegTrabalhoTagsEnum} from "@/enums";

export default {
  name: 'SuccessModal',
  components: {
    vButton
  },
  data() {
    return {
      text: null,
      save_click: false,
      company_name: null,
      nif: null,
      cae: null,
      capital: null,
      total_persons: null,
      new_capital: null,
      new_total_persons: null,

      insurance_value: null,
      premio: null,
      error_message: null,
    }
  },

  methods: {
    openModal(data) {
      this.text = data.text;
      this.total_persons = data.total_persons;
      this.capital = data.capital;
      this.company_name = data.company_name;
      this.cae = data.cae;
      this.nif = data.nif;
      this.new_total_persons = data.new_total_persons;
      this.new_capital = data.new_capital;
      this.save_click = false;
      this.premio = null;
      this.insurance_value = null;
      this.error_message = null;
      $(this.$refs.warningModalDIV).modal('show');
    },
    stepBack() {
      this.$emit('loadStepBack');
    },
    stepSave() {
      const self = this;
      this.$emit('loadStepSave', {
        premio: self.premio,
        insurance_value: self.insurance_value,
        new_total_persons: self.new_total_persons,
        new_capital: self.new_capital
      });
    },
    stepRecalc() {
      if (this.save_click === false) {
        this.save_click = true;
        const self = this;
        // eslint-disable-next-line
        const url = jsRoutes.controllers.Application.recalcTaxa().url;
        // GET request
        axios.post(url, {
          company_name: self.company_name,
          nif: self.nif,
          cae: self.cae,
          capital: self.new_capital,
          total_persons: self.new_total_persons,
        })
            .then(function (response) {
              // console.log(response);
              self.error_message = response.data.caravela_error;
              self.insurance_value = response.data.insurance_value;
              self.premio = response.data.premio;
              self.enableButton();

              if (response.data.caravela_error !== null) {
                if (response.data.caravela_error === 'error')
                  self.error_message = self.$t("simulation.tax.error");
                else if (response.data.caravela_error === 'premio') {
                  self.error_message = self.$t("simulation.tax.price_not_available_recalc");
                }
              }
              if (self.premio === 'Fixo')
                sendDataLayerInfo(SegTrabalhoTagsEnum.NOVA_SIMULACAO_FIXO);
              else
                sendDataLayerInfo(SegTrabalhoTagsEnum.NOVA_SIMULACAO_VARIAVEL);
            })
            .catch(function (response) {
              console.log(response);
              // handle error
              self.enableButton();

              self.error_message = self.$t("simulation.tax.error");
            });
      }
    },
    closeModal() {
      $(this.$refs.warningModalDIV).modal('hide');
    },

    enableButton() {
      this.save_click = false;
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-header,
.modal-footer {
  border:0px;
}

.result-name {
//styleName: H2;
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 0px;
  /* Black */

  color: #110522;
}

.result-value {
//styleName: Big text;
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 68px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 82px;
  letter-spacing: 0.35px;
  text-align: center;
  margin-bottom: 0;
  /* Black */

  color: #110522;
}

.result-time {
  font-family: Gilroy, Gilroy ExtraBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;

  text-transform: uppercase;
  /* Black */

  color: #110522;
}
</style>
