import Vue from "vue";
import VueRouter from "vue-router";
import SegTrabalho from "../views/SegTrabalho.vue";
//import SegTrabalhoIndependent from "../views/SegTrabalhoIndependent.vue"
import Page404 from "../views/404.vue";
import VueCookies from "vue-cookies";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:lang/seguro-trabalho",
      name: "SegTrabalho",
      component: SegTrabalho
    },
    /*{
      path: "/:lang/seguro-trabalho-independente",
      name: "SegTrabalhoIndependent",
      component: SegTrabalhoIndependent
    },*/
    {
      path: "/:lang",
      name: "Index",
      component: SegTrabalho
    },
    {
      path: "/:lang/*",
      name: "IndexDefaultLang",
      component: Page404
    },
    {
      path: "*",
      name: "Default",
      component: Page404
    },
  ]
});

const languages = [/*'en',*/ 'pt'];
router.beforeEach((to, from, next) => {
  const hasLang = to.params && to.params.lang;
  const hadLang = from.params && from.params.lang;

  //console.log(JSON.stringify(to.params) + " " + to.fullPath);
  // If the language hasn't changed since last route we're done
  if (!(hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase())) {
    let redirect = !languages.includes(to.params.lang) && (to.fullPath.endsWith("/" + to.params.lang) || to.fullPath.endsWith("/" + to.params.lang + "/"));
    // Get the save language if it exists
    //if (to.params.lang)
    let lang = VueCookies.get("site_language") != null ? VueCookies.get("site_language") : navigator.language.split('-')[0];

    // Overwrite the language with the route if there is one
    if (hasLang && !redirect) {
      lang = to.params.lang.toLowerCase();
    }

    // Make sure the language is valid
    if (!languages.includes(lang)) {
      lang = 'pt';
    }

    // Set the website language based on the URL
    VueCookies.set("site_language", lang);
    // Redirect to a url with the language

    if (redirect || !hasLang) {
      return next(`/${lang}${to.fullPath}`);
    } else if (!languages.includes(to.params.lang)) {
      let path = to.fullPath;
      path = path.replace("/" + to.params.lang + "/", "/" + lang + "/");
      return next(path);
    }
  }
  next();
});

export default router;

