<template>
  <div class="modal fade"
       tabindex="-1"
       data-keyboard="false" data-backdrop="static"
       role="dialog"
       aria-labelledby="exampleModalCenterTitle"
       aria-hidden="true"
       ref="warningModalDIV">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form v-on:submit.prevent="validate" id="joinForm" novalidate>
          <div class="modal-body custom-p sm">
            <div class="form-group" :class="{ 'has-error': $v.input.current_apolice_number.$error }">
              <label for="current_apolice_number" class="control-label" style="margin-top:60px">{{$t("simulation.current_apolice_number")}}</label>
              <input class="form-colmena" type="text" name="current_apolice_number" id="current_apolice_number" :placeholder='$t("simulation.current_apolice_number.placeholder")'
                     :class="{'is-invalid' : $v.input.current_apolice_number.$error}"
                     v-model.trim="$v.input.current_apolice_number.$model"/>
              <span class="invalid-feedback" v-show="$v.input.current_apolice_number.$error && !$v.input.current_apolice_number.required">
                                {{$t("simulation.required")}}</span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div class="col-sm-10">
              <div class="justify-content-center" style="display: flex">
                <text-button type="submit" :add-arrow="true" :loading="save_click" :text='$t("simulation.confirm")' @validate="validate" ></text-button>
              </div>
            </div>
            <div  class="justify-content-center"  style="text-align: center">
              <span class="main-link" style="margin-top: 16px" v-on:click="noApolice">{{$t("simulation.current_apolice_number.no_number")}}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import textButton from '../TextButton';
import {required} from "vuelidate/lib/validators";

export default {
  name: 'CurrentApoliceModal',
  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    textButton
  },
  data() {
    return {
      input: {
        current_apolice_number: null,
      },
      save_click: false
    }
  },

  validations: {
    input: {
      current_apolice_number: {
        required,
      },
    }
  },
  mounted() {
    window.scrollTo(0,0);
  },

  methods: {
    openModal() {
      this.save_click = false;
      if (this.simulation !== null && this.simulation.current_apolice_number != null) {
        this.input.current_apolice_number = this.simulation.current_apolice_number;
      }
      $(this.$refs.warningModalDIV).modal('show');
    },
    noApolice() {
      if (this.save_click === false) {
        this.save_click = true;
        $(this.$refs.warningModalDIV).modal('hide');
        document.activeElement.blur();
        this.input.current_apolice_number = null;
        this.$emit('saveFromCurrentApolice',
            {
              input: this.input,
            }
        );
      }
    },
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          if (this.$v.input.current_apolice_number.$error)
            document.getElementById("current_apolice_number").focus();
          return;
        }
        $(this.$refs.warningModalDIV).modal('hide');
        document.activeElement.blur();
        this.$emit('saveFromCurrentApolice',
            {
              input: this.input,
            }
        );
      }
    },
    enableButton() {
      this.save_click = false;
    },
  },
};
</script>


(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>

.modal-body {
  padding: 0 1rem;
}
.modal-header,
.modal-footer {
  border:0px;
}

</style>
