<template>
  <div class="container bgcolor">
    <ul class="progressbar">
      <li @click="isActive('step1') && !isSelected('step4') ? change(SegTrabalhoStepsEnum.NO_DATA) : ''" :class="[{'liselected': isSelected('step1')}, {'liactive': isActive('step1')}, {'liclickable': isActive('step1') && !isSelected('step4')}]"><span class="text" :class="[{ 'text-current': isSelected('step1')}, {'liclickable': isActive('step1') && !isSelected('step4')}]">{{$t("simulation.step1")}}</span></li>
      <li @click="isActive('step2') && !isSelected('step4') ? change(SegTrabalhoStepsEnum.INICIO) : ''"  :class="[{'liselected': isSelected('step2')}, {'liactive': isActive('step2')}, {'liclickable': isActive('step2') && !isSelected('step4')}, {'liinactive': isInactive('step2')}]"><span class="text" :class="[{'text-current': isSelected('step2')}, {'liclickable': isActive('step2') && !isSelected('step4')}, {'text-inactive': isInactive('step2')}]">{{$t("simulation.step2")}}</span></li>
      <li @click="isSelected('step3') ? change(SegTrabalhoStepsEnum.TAXA) : ''"  :class="[{'liselected': isSelected('step3')}, {'liactive': isActive('step3')}, {'liclickable': isSelected('step3')}, {'liinactive': isInactive('step3')}]"><span class="text" :class="[{'text-current': isSelected('step3')}, {'liclickable': isSelected('step3')}, {'text-inactive': isInactive('step3')}]">{{$t("simulation.step3")}}</span></li>
      <li :class="[{'liselected': isSelected('step4')}, {'liinactive': isInactive('step4')}]" ><span class="text" :class="[{'text-current': isSelected('step4') }, {'text-inactive': isInactive('step4')}]">{{$t("simulation.step4")}}</span></li>
    </ul>
  </div>
</template>
<script>

import {SegTrabalhoStepsEnum} from "@/enums";

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      SegTrabalhoStepsEnum,
    }
  },
  methods: {
    openStep(value) {
      this.$emit('openStep',
          {
            input: value,
          }
      );
    },isSelected(value) {
      if (value === 'step1') {
        return this.status === SegTrabalhoStepsEnum.NO_DATA;
      }
      if (value === 'step2') {
        return this.status === SegTrabalhoStepsEnum.INICIO || this.status === SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER || this.status === SegTrabalhoStepsEnum.TAXA_NO_CAE;
      }
      if (value === 'step3') {
        return this.status !== SegTrabalhoStepsEnum.NO_DATA && this.status !== SegTrabalhoStepsEnum.INICIO && this.status !== SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER &&
            this.status !== SegTrabalhoStepsEnum.TAXA_NO_CAE && this.status !== SegTrabalhoStepsEnum.COMPRAR_APOLICE && this.status !== SegTrabalhoStepsEnum.TAXA_NO_CAE_EMAIL;
      }
      if (value === 'step4') {
        return this.status === SegTrabalhoStepsEnum.COMPRAR_APOLICE;
      }
      return false;
    },
    isActive(value) {
      if (value === 'step1') {
        return this.status !== SegTrabalhoStepsEnum.NO_DATA;
      }
      if (value === 'step2') {
        return this.status !== SegTrabalhoStepsEnum.NO_DATA && this.status !== SegTrabalhoStepsEnum.INICIO && this.status !== SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER && this.status !== SegTrabalhoStepsEnum.TAXA_NO_CAE_EMAIL;
      }
      if (value === 'step3') {
        return this.status === SegTrabalhoStepsEnum.COMPRAR_APOLICE;
      }
      return false;
    },
    isInactive(value) {
      if (value === 'step2') {
        return this.status === SegTrabalhoStepsEnum.NO_DATA;
      }
      if (value === 'step3') {
        return this.status === SegTrabalhoStepsEnum.NO_DATA || this.status === SegTrabalhoStepsEnum.INICIO || this.status === SegTrabalhoStepsEnum.INICIO_SALARY_HIGHER
            ||  this.status === SegTrabalhoStepsEnum.TAXA_NO_CAE;
      }
      if (value === 'step4') {
        return this.status !== SegTrabalhoStepsEnum.COMPRAR_APOLICE;
      }
      return false;
    },
    change(status) {
      this.$emit('changeStep',
          {
            input: status,
          }
      );
    }
  },

}
</script>

(html comment removed:  Add "scoped" attribute to limit CSS to this component only )
<style scoped>
.bgcolor {
  padding: 24px 0;
}
.container{
  width: 100%;
  z-index: 1;
  margin-top: 25px;
}
.progressbar{
  counter-reset: step;
  color: transparent;
  position: relative;
  left: -20px;
}
.progressbar li{
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}
.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 1px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.liactive:before{
  border-color: #FADB0F;
  background: #FADB0F;
  color: #110522;
  /*cursor: pointer;*/
}
.progressbar li.liactive:after{
  background: #FADB0F;
}
.progressbar li.liactive - li:after{
                             background: #FADB0F;
                           }
.progressbar li.liactive - li:before{
                             border-color: #FADB0F;
                             background: #FADB0F;
                             color: #110522;
                           }

.progressbar li.liselected:before{
  border-color: #FADB0F;
  background: #FDF5C8;
  color: #110522;
  cursor: default;
}
.progressbar li.liselected:after{
  background: #FADB0F;
}
.progressbar li.liselected - li:after{
                               background: rgb(234, 225, 251);
                             }
.progressbar li.liselected - li:before{
                               border-color: #FADB0F;
                               background: rgb(234, 225, 251);
                               color: #FADB0F
                             }


.progressbar li.liinactive:before{
  border-color: #E3E3E3;
  background: white;
  color: #676767;
  cursor: default;
}
.progressbar li.liinactive:after{
  background: #E3E3E3;
}
.progressbar li.liinactive - li:after{
                               background: white;
                             }
.progressbar li.liinactive - li:before{
                               border-color: #E3E3E3;
                               background: white;
                               color: #676767
                             }
.progressbar li.liclickable:before {
                             cursor: pointer;
}

.progressbar li:first-child:after{
  content: none;
}

.text {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #110522;
  cursor: default;
}

.text-current {
  font-weight: 700 !important;
}
.text-inactive {
  color: #676767;
}

.liclickable {
  cursor:pointer;
}
</style>
