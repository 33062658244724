<template>
  <div class="col-sm-11 mt-3">
    <form v-on:submit.prevent="validate" id="joinForm" novalidate>
      <h5 class="main-step">{{$t("simulation.step", {step:1, total:3})}}</h5>
      <h5 class="main-question-qualif">{{$t("simulation.qualify.title")}}</h5>

      <h5 class="main-answer" style="margin-top:34px"> {{$t("simulation.qualify.question1")}}</h5>
      <hr>
      <h5 class="main-answer"> {{$t("simulation.qualify.question2")}}</h5>
      <hr>
      <h5 class="main-answer"> {{$t("simulation.qualify.question3")}}</h5>
      <hr>
      <h5 class="main-answer"> {{$t("simulation.qualify.question4")}}</h5>
      <hr>
      <h5 class="main-answer"> {{$t("simulation.qualify.question5")}}</h5>
      <hr>
      <div style="text-align: center; margin-top: 1.9rem;">
        <radio-buttons :options="options" :minwidth='{"min-width": "143px"}' :selected.sync="selected" @setRadioOption="setRadioOption"></radio-buttons>
      </div>
      <span class="invalid-feedback" :class="{'validate-failed' : $v.selected.$error}" v-show="$v.selected.$error && !$v.selected.required">{{$t("simulation.required")}}</span>
      <div class="clearfix" style="text-align: center; margin-top: 1.9rem;">
        <text-button type="submit" :add-arrow="true" @validate="validate" :loading="save_click" :text='$t("simulation.continue")'></text-button>
      </div>

    </form>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

import textButton from '../TextButton';
import RadioButtons from '../RadioButtons';
import {sendDataLayerInfo} from "@/utils";
import SegTrabalhoTagsEnum from "../../enums/modules/SegTrabalhoTagsEnum";

export default {
  name: 'saveStepQualif',

  props: {
    simulation: {
      type: Object,
      required: false,
    },
  },
  components: {
    textButton,
    RadioButtons,
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      selected: null,
      options: [
        { text: 'Sim', value: 'true' },
        { text: 'Não', value: 'false' },
      ],
      save_click: false,
    };
  },
  validations: {
    selected: {
      required,
    },
  },
  mounted() {
    if (this.simulation !== null && this.simulation.start_date != null) {
      this.selected = 'false';
    }
    window.scrollTo(0,0);
  },
  created() {
    sendDataLayerInfo(SegTrabalhoTagsEnum.EXCLUSOES);
  },
  methods: {
    validate() {
      if (this.save_click === false) {
        this.save_click = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.save_click = false;
          return;
        }
        this.$emit('saveStepQualif',
            {
              input: this.selected,
            }
        );
      }
    },

    setRadioOption(val) {
      this.selected = val.input;
    },

    enableButton() {
      this.save_click = false;
    },
  },
};
</script>
