<template>
  <div>
    <main role="main" class="container-fluid main-section" style="padding: 0px; display:grid;">
      <div style="background-color: #f9fafb; height: 245px">
        <div class="row align-middle margin-panel margin-auto">
          <div class="row align-middle margin-panel margin-auto header-panel">
            <div style="width: 50px"></div>
            <router-link :to="{ name: 'Index'}" custom v-slot="{ href, navigate }" class="imagecenter">
              <a :href="href" @click="navigate">
                <img class="img-fluid imagecenter" style="padding-top: 32px; height:86px" src='/assets/images/indie.png'/>
              </a>
            </router-link>
            <div class="dropdownflag" style="width: 50px">
              <!--span @click="dropDownOpened = !dropDownOpened" class="headerlink">{{ language === 'pt' ? 'PT' : 'EN'}} <span class="headerlinksmall">{{ dropDownOpened ? '&#9650;' : '&#9660;' }}</span></span>
              <div v-show="dropDownOpened" class="dropdown-contentflag">
                <span v-if="language === 'pt'" @click="changeLanguage('en')" class="headerlink" style="padding-right:23px">EN</span>
                <span v-if="language === 'en'" @click="changeLanguage('pt')" class="headerlink" style="padding-right:23px">PT</span>
              </div-->
            </div>
          </div>
        </div>
      </div>
      <login-modal ref="loginModal" :show="showLoginModal"></login-modal>

      <div style="background-color: white">
        <div class="row align-middle margin-panel margin-auto">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
</style>
<script>

import LoginModal from "./components/LoginModal";
import VueCookies from "vue-cookies";

import {mapActions, mapGetters} from 'vuex';
import router from "./router/router";
export default {
  components: {
    LoginModal
  },
  data() {
    return {
      dropDownOpened : false,
    }
  },
  mounted() {
    VueCookies.set("site_language", this.$route.params.lang);
    this.saveLanguage(this.$route.params.lang);
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      language: 'getLanguage',
    }),
  },
  methods: {
    ...mapActions(
        {
          saveLanguage: 'saveLanguage',
        },
    ),
    changeLanguage(lang) {
      VueCookies.set("site_language", lang);
      this.saveLanguage(lang);
      this.dropDownOpened = false;
      router.push(this.$route.fullPath.replace("/" + this.$route.params.lang, "/" + lang));
    },
  }
}
</script>

<style scoped>

.dropdownflag {
  padding: 2px 6px;
  padding-top: 52px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width:50px;
}


@media (max-width: 768px) {
  .dropdownflag {
    margin-top:0;
  }
}

.dropdown-contentflag {
  display: block;
  position: absolute;
  padding: 2px 6px;
  padding-top: 0;
  z-index: 1;
  right: 0px;
  width:50px;
}

.header-panel {
  width: 685px;
}
@media (max-width: 684px) {
  .header-panel {
    width: 100%;
  }
}

.headerlinksmall,
.headerlink {
  font-family: "Gilroy", "Gilroy ExtraBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  vertical-align: top;
}
.headerlinksmall {
  font-size: 12px;
}
</style>
